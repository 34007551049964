import React, {useEffect} from 'react';
import './DerinBilgiIntroducePage.scss'
import {useAppContext} from "../../context/ContextProvider";
import classNames from "classnames";
import DerinBilgiIntroduce1 from "../../images/DerinBilgiIntroduce.png";
import PageHelmet from "../../components/page-helmet/PageHelmet";


const DerinBilgiIntroducePage = () => {
    const classes = classNames('db-derinbilgi-introduce-page')
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div className={classes}>
            <PageHelmet
                title={'Hakkımızda'}
                description={'Hakkımızda'}
                keywords={'hakkimizda, about, derinbilgi, company'}
                noIndex={false}
            />
            <div className="derinbilgi-introduce-page">
                <div className="derinbilgi-introduce-page-title">
                    2006 sonunda kurulan Derin Bilgi
                </div>
                <div className="derinbilgi-introduce-page-image">
                    <img src={DerinBilgiIntroduce1} alt=""/>
                </div>
                <div className="derinbilgi-introduce-page-subtitle">
                    <div className="derinbilgi-introduce-page-subtitle-blue-dot"></div>
                    <p>
                        Hikayemiz
                    </p>
                </div>
                <div className="derinbilgi-introduce-page-story">
                    <p>
                        2006 sonunda kurulan Derin Bilgi, 2 yıllık bir araştırma ve geliştirme döneminin
                        ardından,ürünlerini
                        sahada uygulamaya başladı. Stratejik olarak seçilen projelerle, ürünlerin giderek olgunlaşması,
                        her
                        müşteride daha büyük projelere hazırlanması sağlandı. 3. yılında, bir zincir mağazanın tüm
                        süreçlerini yönetebilir duruma geldi.
                    </p>
                    <p>
                        Derin Bilgi'nin temel amacı, şirketlerin verimliliğini ve yönetilebilirliğini arttırmaya yönelik
                        yazılım sistemleri geliştirmek ve en büyük zorlukların yaşandığı projelendirme sürecinde
                        kusursuz
                        bir eğitimle bu sistemleri hayata geçirmektir. Şirketin itici gücü, 16 yıllık perakende sektörü
                        otomasyon sistemleri deneyimini kullanarak, kaliteli projelerin gerektirdiği esnekliğe uyabilme
                        ve
                        değişen ihtiyaçları müşterilerin ortak yararına en uygun biçimde ürünlerine yansıtma
                        kararlılığıdır.
                    </p>
                </div>
                <div className="derinbilgi-introduce-page-subtitle">
                    <div className="derinbilgi-introduce-page-subtitle-blue-dot"></div>
                    <p>
                        Şirket Kurucusu Barış Parıltı Hakkında
                    </p>
                </div>
                <div className="derinbilgi-introduce-page-story">
                    <p>
                        ODTÜ Elektrik-Elektronik Mühendisiliği mezunu Barış PARILTI, tüm iş hayatı boyunca perakende
                        sektöründe proje uygulamaları, yazılım geliştiriciliği ve yazılım yöneticiliği yapmıştır. Mali
                        onaylı önofis yazılımı üreten az sayıdaki yazılımcılardan birisidir. Yurt içi ve yurt dışı
                        kaynakları sürekli izleyerek, güncel teknoloji ile sektörün yazılım ve proje ihtiyaçlarını
                        karşılamak üzere çalışmalarını sürdürmektedir.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DerinBilgiIntroducePage;