import {default as ApiHelper} from '../helpers/ApiHelper';
import {ContactFormType, FormType} from "../components/forms/types";
import {ErrorMessageContent, IResponse} from "../helpers/types";
// todo: ileride dbMessage'a bağlanmalı

const useApiHelper = () => {
    const apiHelper = ApiHelper.getInstance();

    function parseErrorResponse(
        response: IResponse
    ): Record<string, string[]> | null {
        if (response.errors) {
            const newErrorMessages: Record<string, string[]> = {};
            response.errors.forEach((error: ErrorMessageContent) => {
                // error code bu sekildede gelebiliyor. "Address.PhoneNumber"
                // split edip son indexi alıyoruz.
                let errorCodeFormat = error.code;
                if (errorCodeFormat.includes('.')) {
                    errorCodeFormat = error.code.split('.').slice(-1)[0];
                }

                // error code'un ilk harfini küçült.
                errorCodeFormat =
                    errorCodeFormat.charAt(0).toLowerCase() + errorCodeFormat.slice(1);

                if (!newErrorMessages[errorCodeFormat]) {
                    newErrorMessages[errorCodeFormat] = [];
                }
                newErrorMessages[errorCodeFormat].push(error.description);
            });
            return newErrorMessages;
        }
        return null;
    }

    // Home API
    const getHomeSliders = async () => {
        return await apiHelper.getHomeSliders();
    };

    // News
    const getNews = async () => {
        return await apiHelper.getNews();
    };
    const getNewsPaging = async (page:number) => {
        return await apiHelper.getNewsPaging(page);
    };
    const getNewsDetail = async (slug:string) => {
        return await apiHelper.getNewsDetail(slug);
    };
    // Adverts
    const getAdverts = async () => {
        return await apiHelper.getAdverts();
    };

    // Remedies
    const getRemedies = async () => {
        return await apiHelper.getRemedies();
    };

    const getRemediesDetailWithSlug = async (slug: string | undefined) => {
        return await apiHelper.getRemediesDetailWithSlug(slug);
    };

    const getRemediesDetail = async () => {
        return await apiHelper.getRemediesDetail();
    };

    // Products
    const getProducts = async () => {
        return await apiHelper.getProducts();
    };
    const getProductDetail = async (id: string | undefined) => {
        return await apiHelper.getProductDetail(id);
    };


    // Success Stories - Details
    const getSuccessStories = async () => {
        return await apiHelper.getSuccessStories();
    };
    const getSuccessStoriesDetail = async (slug: string | undefined) => {
        return await apiHelper.getSuccessStoryDetail(slug);
    };

    // Curiosities - Details
    const getCuriosities = async () => {
        return await apiHelper.getCuriosities();
    };

    const getCuriositiesDetail = async (slug: string | undefined) => {
        return await apiHelper.getCuriositiesDetail(slug);
    };

    // Reference Stores
    const getReferenceStores = async () => {
        return await apiHelper.getReferenceStores();
    };

    const getReferenceStoreDetail = async (name: string) => {
        return await apiHelper.getReferenceStoreDetail(name);
    };

    // Departments
    const getDepartments = async () => {
        return await apiHelper.getDepartments();
    };

    const addForm = async (
        form: Partial<FormType>,
    ) => {
        return await apiHelper.addForm(
            form,
        );
    };

    const addContactForm = async (
        form: Partial<ContactFormType>,
    ) => {
        return await apiHelper.addContactForm(
            form,
        );
    };

    return {
        getHomeSliders,
        getNews,
        getAdverts,
        getSuccessStories,
        getSuccessStoriesDetail,
        getCuriosities,
        getCuriositiesDetail,
        getReferenceStores,
        getReferenceStoreDetail,
        getDepartments,
        addForm,
        addContactForm,
        parseErrorResponse,
        getRemedies,
        getRemediesDetailWithSlug,
        getRemediesDetail,
        getProducts,
        getProductDetail,
        getNewsDetail,
        getNewsPaging,
    };
};

export default useApiHelper;
