import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface FilterPageContextType {
  tempValue?: string;
  setTempValue: Dispatch<SetStateAction<string | undefined>>;
}

const AppContext = createContext<FilterPageContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useFilterPageContext must be used within a FilterPageProvider');
  }
  return context;
};
interface AppPageProviderProps {
  children: ReactNode;
}

export const ContextProvider: React.FC<AppPageProviderProps> = ({ children }) => {
  const [tempValue, setTempValue] = useState<string | undefined>();

  return (
    <AppContext.Provider value={{ tempValue, setTempValue }}>
      {children}
    </AppContext.Provider>
  );
};
