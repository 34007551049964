import React from 'react';
import "./ReferenceStore.scss"
import classNames from "classnames";
import {ReactComponent as ArrowButtonOutlined} from "../../images/arrowButtonOutlined2.svg";
import {Link} from "react-router-dom";
import {ReferenceStoreType} from "../../helpers/types";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import {truncateTextWithLine} from "../../helpers/Formatting";
import NoImage from "../../images/noImage.png";


interface ReferenceStoreProps {
    stores: ReferenceStoreType,
    url: string
}

const ReferenceStore: React.FC<ReferenceStoreProps> = (props) => {
    const {stores, url} = props
    const classes = classNames("db-reference-stores")

    return (
        <div className={classes}>
            <div className="reference-stores-card">
                <div>
                    <img
                        src={stores.fotograf ? `https://derinbilgi-api.derinpos.com/${stores.fotograf}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt="store-img"
                    />
                    <div className="reference-stores-card-title">
                        {stores.baslik}
                    </div>
                    <div className="reference-stores-card-description" style={truncateTextWithLine(3)}>
                        {stores.alt_baslik}
                    </div>
                </div>
                <div className="reference-stores-card-detail">
                    <Link to={`${ApplicationRoutes.askUs}/${stores.slug}`}>
                        <div>
                            Detaylı İncele
                        </div>
                        <ArrowButtonOutlined/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ReferenceStore;