import React from 'react';
import './HomeSlider.scss'
import classNames from "classnames";
import DetailButton from "../../../detail-button/DetailButton";
import {Col, Row} from "antd";
import {useIsSmallScreen} from "../../../../hooks/useIsSmallScreen";
import {HomeSlidersType} from "../../../../helpers/types";
import {Link} from "react-router-dom";
import NoImage from "../../../../images/noImage.png";

interface HomeSliderProps {
    homeSlider: HomeSlidersType
}

const HomeSlider: React.FC<HomeSliderProps> = (props) => {
    const {homeSlider} = props
    const classes = classNames('db-home-slider')
    const isSmallScreen = useIsSmallScreen(991);
    return (
        <div className={classes}>
            <Row gutter={[32, 64]} className="home-slider">
                <Col xs={24} lg={12} order={isSmallScreen ? 1 : 0} className="home-slider-text">
                    <div className="home-slider-text-title">
                        {homeSlider.isim}
                    </div>
                    <div className="home-slider-text-subtitle"
                         dangerouslySetInnerHTML={{__html: homeSlider.metin}}/>
                    {
                        homeSlider.url && (
                            <Link to={homeSlider.url} target="_blank">
                                <div className="home-slider-text-detail-button">
                                    <DetailButton/>
                                </div>
                            </Link>
                        )
                    }
                </Col>
                <Col xs={24} lg={12} order={isSmallScreen ? 0 : 1} className="home-slider-image">
                    <img
                        src={homeSlider.resim ? `https://derinbilgi-api.derinpos.com/${homeSlider.resim}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt="slider-img"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default HomeSlider;