import React, {useEffect} from "react";
import {useAppContext} from "../../context/ContextProvider";
import ContactForm from "../../components/forms/contact-form/ContactForm";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const ContactPage = () => {
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue('');
    }, []);

    return (
        <div>
            <PageHelmet
                title={'İletişim'}
                description={'İletişim'}
                keywords={'iletisim, contact'}
                noIndex={false}
            />
            <ContactForm
                pageTitle="İletişim"
                title="DERİN BİLGİ SİSTEMLERİ"
                subTitle="Yazılım ve Danışmanlık Ticaret Limited Şirketi"
                location="Uphill Towers A-Blok Kat: 19 D: 114 Ataşehir / İstanbul / Türkiye"
                phoneNumber={"+90 216 740 00 36"}
                email="info@derinbilgi.com.tr"
                mapTag="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d96357.92195431299!2d29.096584!3d40.9993225!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14cac89e1528d2ef%3A0x20aac3998137d31!2sDerin%20Bilgi%20Sistemleri%20Yaz%C4%B1l%C4%B1m%20ve%20Dan%C4%B1%C5%9Fmanl%C4%B1k%20Tic.%20Ltd.%20%C5%9Eti.%20Barbaros%20Kolektif%20House%20Nida%20Kule%20Begonya%20Sk.%20No%3A1%2F2%2C%2034746%20Ata%C5%9Fehir%2F%C4%B0stanbul!3m2!1d40.9993607!2d29.0965603!5e0!3m2!1str!2str!4v1723722444539!5m2!1str!2str"
            />
        </div>
    );
};

export default ContactPage;
