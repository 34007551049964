export const ApplicationRoutes = {
    home: '/',
    contact: '/iletisim',
    successStory: '/basari-hikayeleri-liste',
    successStoryDetail: '/basari-hikayeleri-detay',
    news: '/haber-liste',
    newsDetail: '/haber-detay',
    products: '/urunler',
    productDetail: '/urun-detay',
    remedies: '/cozumlerimiz-liste',
    remediesDetail: '/cozumlerimiz-detay',
    about: '/hakkimizda',
    ourInterests: '/ilgi-alanlarimiz',
    adverts: '/ilanlarimiz',
    certificationForm: '/derinsis-sertifikasyon-egitimine-katilmak-istiyorum',
    learnDerinsisForm: '/derinsis-ogrenmek-istiyorum',
    humanResourceForm: '/insan-kaynaklari-formu',
    chainStores: '/zincir-magazalar',
    stationery: '/kirtasiyeler',
    wholesaleDistribution: '/toptan-dagitim',
    ecommerceProjects: '/eticaret-projeleri',
    logisticsCompanies: '/lojistik-sirketleri',
    toplaioProjects: '/topla-topla.io-pazaryeri-market-ici-hizli-toplama-uygulamasi',
    askUs: '/bizi-sorun',
    microsoftPartner: '/microsoft-is-ortakligi',
    curiosities: '/merak-ettikleriniz-liste',
    curiositiesDetail: '/merak-ettikleriniz-detay',
    vcard: '/vcard',
    page500: '/hata/sayfasi',
    page404: '*',
};
