import React from 'react';

interface DynamicUpArrowButtonProps {
    fillColor?: string;
}

const DynamicUpArrowButton: React.FC<DynamicUpArrowButtonProps> = ({fillColor = '#8EE558'}) => (
    <svg width="30" height="30" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.3336" cy="14.4999" r="13.6075" transform="rotate(-90 14.3336 14.4999)" fill={fillColor}/>
        <path
            d="M20.1871 16.9508C20.2506 16.8913 20.2865 16.8103 20.287 16.7257C20.2875 16.6837 20.2788 16.6421 20.2616 16.6034C20.2444 16.5647 20.2191 16.5297 20.1871 16.5006L14.5757 11.1926C14.5446 11.1628 14.5076 11.1391 14.4667 11.123C14.4258 11.1068 14.382 11.0985 14.3377 11.0985C14.2935 11.0985 14.2496 11.1068 14.2088 11.123C14.1679 11.1391 14.1309 11.1628 14.0998 11.1926L8.48719 16.5006C8.45421 16.5298 8.42777 16.565 8.40944 16.604C8.39112 16.6431 8.38129 16.6852 8.38054 16.7279C8.37979 16.7706 8.38813 16.8131 8.40506 16.8527C8.422 16.8923 8.44718 16.9283 8.47912 16.9585C8.51106 16.9887 8.54909 17.0125 8.59096 17.0285C8.63283 17.0445 8.67768 17.0524 8.72284 17.0517C8.768 17.051 8.81254 17.0417 8.85382 17.0244C8.89511 17.007 8.93228 16.982 8.96313 16.9508L14.3377 11.8679L19.7111 16.9508C19.7423 16.9805 19.7794 17.0041 19.8202 17.0201C19.8611 17.0362 19.9049 17.0445 19.9491 17.0445C19.9934 17.0445 20.0371 17.0362 20.078 17.0201C20.1188 17.0041 20.1559 16.9805 20.1871 16.9508Z"
            fill="#141414"/>
    </svg>
);

export default DynamicUpArrowButton;
