import React from 'react';
import './NewsItem.scss'
import classNames from "classnames";
import {NewsType} from "../../../helpers/types";
import {Col, Row} from "antd";
import DetailButton from "../../detail-button/DetailButton";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import DateBadge from "../../date-badge/DateBadge";
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../../consts/ApplicationConst";
import NoImage from "../../../images/noImage.png";


interface NewsItemProps {
    news: NewsType
}

const NewsItem: React.FC<NewsItemProps> = (props) => {
    const classes = classNames('db-news-item')
    const {news} = props
    const isMobile = useIsSmallScreen(991)

    return (
        <div className={classes}>
            <Row gutter={[48, 48]} className="news-item">
                <Col xs={24} lg={12} className="news-item-text" order={isMobile ? 1 : 0}>
                    <DateBadge date={news.tarih}/>
                    <div className="news-item-text-title">{news.baslik}</div>
                    <div className="news-item-text-description">{news.alt_baslik}</div>
                    <div className="news-item-text-detail-button">
                        <Link to={`${ApplicationRoutes.newsDetail}/${news.slug}`}><DetailButton/></Link>
                    </div>
                </Col>
                <Col xs={24} lg={12} className="news-item-image">
                    <img
                        src={news.fotograf ? `https://derinbilgi-api.derinpos.com/${news.fotograf}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt=""/>
                </Col>
            </Row>
        </div>
    );
};

export default NewsItem;