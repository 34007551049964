import React from 'react';
import InfinityScroll from "../../infinity-scroll/InfinityScroll";
import './References.scss'
import classNames from "classnames";
import {Button, Col, Row} from "antd";
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../../consts/ApplicationConst";

const References = () => {
    const classes = classNames('db-references');

    return (
        <div className={classes}>
            <Row className="references">
                <Col xs={24} lg={12} className="references-content">
                    <div className="header-subtitle">
                        Referanslarımız
                    </div>
                    <div className="references-content-text">
                        +20'den fazla lider mağaza zincirici şirketine çözümler üretiyoruz
                    </div>
                    <Link to={ApplicationRoutes.chainStores}>
                        <Button className="discover-button" type={"text"}>
                            Keşfet
                        </Button>
                    </Link>
                </Col>
                <Col xs={24} lg={12} className="references-infinity-scroll">
                    <div>
                        <InfinityScroll/>
                        <Link to={ApplicationRoutes.chainStores}>
                            <Button className="discover-button" type={"text"}>
                                Keşfet
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default References;