import React, {useEffect, useState} from 'react';
import "./DerinSisInformationForm.scss";
import classNames from "classnames";
import {Checkbox, Col, Form, Input, Row, Spin} from "antd";
import useApiHelper from "../../../../hooks/useApiHelper";
import {DepartmentType} from "../../../../helpers/types";

interface DerinsisInformationFormProps {
    formName: string
    companyName: string
    derinsisDepartment: string
}

const DerinSisInformationForm: React.FC<DerinsisInformationFormProps> = (props) => {
    const {formName, companyName, derinsisDepartment} = props;
    const classes = classNames("db-derinsis-information-form");
    const {getDepartments} = useApiHelper();
    const [dataDepartments, setDataDepartments] = useState<DepartmentType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        try {
            setLoad(true);
            const response = await getDepartments();
            if (response?.Departmanlar) {
                setDataDepartments(response.Departmanlar);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <div className="form-title">
                DerinSİS Bilgisi
            </div>

            {
                formName !== "derinsis-ogrenmek-istiyorum" && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name={companyName}
                                rules={[{required: true, message: "Lütfen firma adınızı giriniz!"}]}
                            >
                                <Input placeholder="Firma Adı"/>
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={derinsisDepartment}
                        valuePropName="checkedValues"
                        getValueFromEvent={(e) => e}
                    >
                        <Checkbox.Group>
                            <div className="department-box">
                                <div className="department-box-title">
                                    Departman seçiniz
                                </div>
                                <Row gutter={[24, 24]} justify={"start"}>
                                    {dataDepartments && dataDepartments
                                        .filter(department => department.sira)
                                        .map((department, index) => (
                                            <Col xs={12} sm={8} md={8} lg={6} xl={4} key={index}>
                                                <Checkbox value={department.id}>
                                                    {department.departman}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default DerinSisInformationForm;
