import React from 'react';
import './ReviewListDetail.scss'
import classNames from "classnames";
import DateBadge from "../date-badge/DateBadge";
import NoImage from '../../images/noImage.png'


export interface ReviewListDetailProps {
    baslik: string | undefined,
    alt_baslik: string | undefined,
    metin: string | undefined,
    fotograf: string | undefined
    tarih?: string | undefined
}

const ReviewListDetail: React.FC<ReviewListDetailProps> = (props) => {
    const classes = classNames('db-review-list-detail')
    const {baslik, alt_baslik, metin, fotograf, tarih} = props

    return (
        <div className={classes}>
            <div className="review-list-detail">
                <div className="review-list-detail-image">
                    <img
                        src={fotograf ? `https://derinbilgi-api.derinpos.com/${fotograf}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt="detail-img"
                    />
                </div>
                <div className="review-list-detail-content">
                    {
                        tarih && (
                            <div className="review-list-detail-content-date">
                                <DateBadge date={tarih}/>
                            </div>
                        )
                    }
                    <div className="header-title">
                        {baslik}
                    </div>
                    <div className="review-list-detail-content-subtitle">
                        {alt_baslik}
                    </div>
                    <div
                        className="review-list-detail-content-description"
                        dangerouslySetInnerHTML={{__html: metin!}}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewListDetail;