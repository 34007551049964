import React, {useEffect, useState} from 'react';
import './RemediesListPage.scss'
import {useAppContext} from "../../context/ContextProvider";
import RemediesListItem from "../../components/remedies-list-item/RemediesListItem";
import classNames from "classnames";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {Remedies} from "../../helpers/types";
import {Spin} from "antd";

const RemediesListPage = () => {
    const classes = classNames('db-remedies-list-page')
    const {setTempValue} = useAppContext();
    const {getRemedies} = useApiHelper();
    const [dataRemedies, setDataRemedies] = useState<Remedies[]>([]);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true);
        try {
            const response = await getRemedies();
            if (response.cozumlergruplar) {
                setDataRemedies(response.cozumlergruplar);  // Set detaylar directly
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false);
        }
    }
    useEffect(() => {
        setTempValue("");
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Çözümlerimiz'}
                description={'Çözümlerimiz'}
                keywords={'cozumler, remedies'}
                noIndex={false}
            />
            <div className="remedies-list-page">
                <div className="remedies-list-page-text">
                    <div className="header-title">
                        Yolculuğunuza Çözümlerimizle Başlayın
                    </div>
                    <div className="remedies-list-page-text-subtitle">
                        Hızlı, pratik ve kurallı çalışma ortamında, kullanıcıları motive ediyor ve hayatı
                        kolaylaştırıyoruz.
                    </div>
                </div>
                {dataRemedies && dataRemedies?.map((remediesListItem, index) => (
                    <div className="remedies-list-page-item">
                        <RemediesListItem
                            key={index}
                            remediesListItem={remediesListItem}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RemediesListPage;