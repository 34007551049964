import React, {useEffect} from "react";
import News from "../../components/news/News";
import {useAppContext} from "../../context/ContextProvider";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const NewsPage = () => {
    const {setTempValue} = useAppContext();
    useEffect(() => {
        setTempValue('');
    }, []);

    return (
        <div>
            <PageHelmet
                title={'Haberler'}
                description={'Haberler'}
                keywords={'haberler, news-page'}
                noIndex={false}
            />
            <News/>
        </div>
    );
};

export default NewsPage;
