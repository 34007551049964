/* eslint-disable react-hooks/rules-of-hooks */
import "./Footer.scss";
import classNames from "classnames";
import {Collapse} from "antd";
import React from "react";
import {Link} from "react-router-dom";

// contact-form icons
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import SocialMedias from "../social-medias/SocialMedias";
import {FooterItems} from "../../data/FooterItems";

// svg
import location from "../../images/location.svg";
import phone from "../../images/phone.svg";
import email from "../../images/mail.svg";

const {Panel} = Collapse;

const Footer = () => {
    const classes = classNames("db-footer");
    const isSmallScreen = useIsSmallScreen(1024);

    return (
        <div className={classes}>
            {!isSmallScreen ? (
                <div>
                    <div className="footer">
                        <div className="footer-content">
                            {FooterItems.map((item, key) => (
                                <div>
                                    <div className="footer-content-title" key={key}>
                                        {item.label}
                                    </div>
                                    <div className="footer-content-subtitles">
                                        <ul>
                                            {item.children?.map((child, index) => (
                                                <li>
                                                    {item.label === "İletişim" ? (
                                                        <div className="contact">
                                                            <div className="contact-icon">
                                                                <img src={child.icon} alt=""/>
                                                            </div>
                                                            <div className="contact-text" key={index}>
                                                                {child.label}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Link to={child.url || "/"}>{child.label}</Link>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <div className="footer-content-title">
                                    İletişim
                                </div>
                                <div className="footer-content-subtitles">
                                    <ul>
                                        <li>
                                            <div className="contact">
                                                <div className="contact-icon">
                                                    <img src={location} alt=""/>
                                                </div>
                                                <div className="contact-text">
                                                    Uphill Towers A-Blok Kat: 19 D: 114 Ataşehir / İstanbul
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact">
                                                <div className="contact-icon">
                                                    <img src={phone} alt=""/>
                                                </div>
                                                <div className="contact-text">
                                                    <a href="tel:+902167400036">
                                                        + 90 216 740 00 36
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact">
                                                <div className="contact-icon">
                                                    <img src={email} alt=""/>
                                                </div>
                                                <div className="contact-text">
                                                    <a href="mailto:info@derinbilgi.com.tr}">
                                                        info@derinbilgi.com.tr
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-social-medias">
                                                <SocialMedias/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        Copyright © {new Date().getFullYear()} Derin Bilgi Sistemleri
                    </div>
                </div>
            ) : (
                <div className="collapse-footer">
                    <Collapse accordion bordered={false}>
                        {FooterItems.map((item, index) => (
                            <Panel header={item.label} key={index}>
                                {item.children?.map((child, index) => (
                                    <div key={index}>
                                        <Link to={child.url || "/"}
                                              className="collapse-footer-label">{child.label}</Link>
                                    </div>
                                ))}
                            </Panel>
                        ))}
                        <Panel header="İletişim" key={4}>
                            <div className="collapse-footer-contact">
                                <img src={location} alt=""/>
                                <div>
                                    Uphill Towers A-Blok Kat: 19 D: 114 Ataşehir / İstanbul
                                </div>
                            </div>
                            <div className="collapse-footer-contact">
                                <img src={phone} alt=""/>
                                <a href="tel:+902167400036">
                                    + 90 216 740 00 36
                                </a>
                            </div>
                            <div className="collapse-footer-contact">
                                <img src={email} alt=""/>
                                <a href="mailto:info@derinbilgi.com.tr}">
                                    info@derinbilgi.com.tr
                                </a>
                            </div>
                            <div className="contact-social-medias">
                                <SocialMedias/>
                            </div>
                        </Panel>
                    </Collapse>
                    <div className="footer-copyright">
                        Copyright © {new Date().getFullYear()} Derin Bilgi Sistemleri
                    </div>
                </div>
            )}
        </div>
    );
};

export default Footer;
