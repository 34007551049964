import React from 'react';
import './ProductDetailsRemedies.scss'
import classNames from "classnames";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import {RemediesDetail} from "../../../helpers/types";
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../../consts/ApplicationConst";
import DynamicArrowButton from "../../option-arrow/DynamicArrowButton";
import {truncateText} from "../../../helpers/Formatting";

interface ProductDetailsRemediesProps {
    urun_rengi: string | undefined,
    detaylar?: RemediesDetail[]
}

const ProductDetailsRemedies: React.FC<ProductDetailsRemediesProps> = (props) => {
    const classes = classNames('db-product-details-remedies')
    const {urun_rengi, detaylar} = props
    const isSmallScreen = useIsSmallScreen(768)
    const isMediumScreen = useIsSmallScreen(1024)

    return (
        <div className={classes}>
            {
                !isSmallScreen
                    ? (
                        <Swiper
                            grabCursor={true}
                            centeredSlides={false}
                            loop={false}
                            pagination={{clickable: true}}
                            spaceBetween={32}
                            slidesPerView={isMediumScreen ? 2 : 3}
                        >
                            {
                                detaylar?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div
                                            className='product-details-remedy-card'
                                            style={{
                                                border: `2px solid ${urun_rengi}`
                                            }}
                                        >
                                            <div className="product-details-remedy-card-title">
                                                {item.baslik}
                                            </div>
                                            <div className="product-details-remedy-card-description">
                                                {!isSmallScreen && truncateText(item.alt_baslik, 85)}
                                            </div>
                                            <div>
                                                <Link to={`${ApplicationRoutes.remediesDetail}/${item.slug}`}>
                                                    <DynamicArrowButton fillColor={urun_rengi}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }

                        </Swiper>
                    )
                    : (
                        detaylar?.map((item, index) => (
                            <div
                                key={index}
                                className={`product-details-remedy-card`}
                                style={{
                                    border: `2px solid ${urun_rengi}`
                                }}
                            >
                                <div className="product-details-remedy-card-title">
                                    {item.baslik}
                                </div>
                                <div className="product-details-remedy-card-description">
                                    {item.alt_baslik}
                                </div>
                                <div>
                                    <Link to={`${ApplicationRoutes.remediesDetail}/${item.slug}`}>
                                        <DynamicArrowButton fillColor={urun_rengi}/>
                                    </Link>
                                </div>
                            </div>
                        ))
                    )
            }
        </div>
    );
};

export default ProductDetailsRemedies;