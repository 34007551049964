import React, {useEffect, useState} from "react";
import {useAppContext} from "../../context/ContextProvider";
import ReviewListDetail from "../../components/review-list-detail/ReviewListDetail";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ReviewListType} from "../../helpers/types";
import {Spin} from "antd";
import {useParams} from "react-router";


const SuccessStoriesDetailsPage = () => {
    const {setTempValue} = useAppContext();
    const {getSuccessStoriesDetail} = useApiHelper();
    const [dataSuccessStoriesDetail, setDataSuccessStoriesDetail] = useState<ReviewListType | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);
    const {slug} = useParams();

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getSuccessStoriesDetail(slug);
            if (response?.BasariHikayesi) {
                setDataSuccessStoriesDetail(response.BasariHikayesi);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }


    useEffect(() => {
        setTempValue("");
        getData()
    }, []);

    return (
        <div>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Başarı Hikayelerimiz'}
                description={'Başarı Hikayelerimiz'}
                keywords={'basari hikayleri, success stories'}
                noIndex={false}
            />
            <ReviewListDetail
                baslik={dataSuccessStoriesDetail?.baslik}
                alt_baslik={dataSuccessStoriesDetail?.alt_baslik}
                metin={dataSuccessStoriesDetail?.metin}
                fotograf={dataSuccessStoriesDetail?.fotograf}
            />
        </div>
    );
};

export default SuccessStoriesDetailsPage;
