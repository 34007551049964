import React, {useEffect, useState} from 'react';
import './RemediesDetailPage.scss'
import {useAppContext} from "../../context/ContextProvider";
import classNames from "classnames";
import {Col, Row, Spin} from "antd";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {RemediesDetail} from "../../helpers/types";
import {truncateText} from "../../helpers/Formatting";
import {useParams} from "react-router";
import NoImage from "../../images/noImage.png";

const RemediesDetailPage = () => {
    const classes = classNames('db-remedies-detail-page')
    const {setTempValue} = useAppContext();
    const {getRemediesDetailWithSlug} = useApiHelper();
    const [dataRemedies, setDataRemedies] = useState<RemediesDetail>();// Change to RemediesDetail[]
    const [load, setLoad] = useState<boolean>(false);
    const {slug} = useParams()
    const getData = async () => {
        setLoad(true);
        try {
            const response = await getRemediesDetailWithSlug(slug);
            if (response.Cozum) {
                setDataRemedies(response.Cozum);  // Set detaylar directly
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false);
        }
    }
    useEffect(() => {
        setTempValue("");
        getData();
    }, []);
    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Çözümlerimiz'}
                description={'Çözümlerimiz'}
                keywords={'cozumler, remedies'}
                noIndex={false}
            />
            <div className="remedies-detail-page">
                <div className="remedies-detail-page-detail">
                    <div className="remedies-detail-page-detail-text">
                        <div className="header-title">
                            {dataRemedies?.baslik}
                        </div>
                        <div className="header-description">
                            {dataRemedies?.alt_baslik}
                        </div>
                        <div className="remedies-detail-page-detail-text-description"
                             dangerouslySetInnerHTML={{__html: truncateText(dataRemedies?.metin ? dataRemedies.metin : '', 350)}}/>
                    </div>
                    <div className="remedies-detail-page-detail-image">
                        <img
                            src={dataRemedies?.fotograf ? `https://derinbilgi-api.derinpos.com/${dataRemedies?.fotograf}` : NoImage}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                const target = e.target as HTMLImageElement;
                                target.src = NoImage!;
                            }}
                            className="detail-img" alt=""/>
                    </div>
                </div>
                <div className="remedies-detail-page-introduce">
                    <Row gutter={[48, 48]}>
                        {dataRemedies?.alt_bilgiler?.map((data, index) => (
                            <Col lg={24} xl={12} key={index}>
                                <div className="remedies-detail-page-introduce-title">
                                    {data.baslik}
                                </div>
                                <div className="remedies-detail-page-introduce-description">
                                    {data.aciklama}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RemediesDetailPage;