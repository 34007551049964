import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import "./ProductDetailsBanner.scss";
import classNames from "classnames";
import {Col, Row} from "antd";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import {useParams} from "react-router";
import useApiHelper from "../../../hooks/useApiHelper";
import {ProductDetailType, ProductType} from "../../../helpers/types";
import NoImage from "../../../images/noImage.png";

interface ProductDetailBannerProps {
    setHomeImage: Dispatch<SetStateAction<string | undefined>>;
}

const ProductDetailsBanner: React.FC<ProductDetailBannerProps> = (props) => {
    const {setHomeImage} = props
    const classes = classNames("db-product-details-banner");
    const isSmallScreen = useIsSmallScreen(991);
    const {id} = useParams()
    const {getProductDetail} = useApiHelper();
    const [dataProductDetail, setDataProductDetail] = useState<ProductDetailType | undefined>(undefined);
    const [dataProduct, setDataProduct] = useState<ProductType | undefined>(undefined);

    const getData = async () => {
        try {
            const response = await getProductDetail(id);
            if (response?.urundetay) {
                setDataProductDetail(response.urundetay);
                setDataProduct(response.urunbilgileri);
                setHomeImage(response.urundetay.banner_arkaplan_resim)
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={classes}>
            <div
                className={`product-details-banner`}
                style={{
                    borderBottom: `1px solid ${dataProduct?.urun_rengi}`
                }}
            >
                <Row gutter={[30, 30]} className="product-details-banner-content">
                    <Col
                        xs={24} lg={12}
                        order={isSmallScreen ? 1 : 0}
                        className="product-details-banner-content-text"
                    >
                        <div className="product-details-banner-content-text-title">
                            {dataProductDetail?.banner_baslik}
                        </div>
                        <div className="product-details-banner-content-text-subtitle">
                            {dataProductDetail?.banner_alt_baslik}
                        </div>
                    </Col>
                    <Col
                        xs={24} lg={12}
                        order={isSmallScreen ? 0 : 1}
                        className="product-details-banner-content-image"
                    >
                        <img
                            src={dataProductDetail?.banner_resim ? `https://derinbilgi-api.derinpos.com/${dataProductDetail?.banner_resim}` : NoImage}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                const target = e.target as HTMLImageElement;
                                target.src = NoImage!;
                            }}
                            alt="banner-img"
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProductDetailsBanner;
