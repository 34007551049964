import React from 'react';
import "./MessageForm.scss"
import {Col, Form, Input, Row} from "antd";
import classNames from "classnames";

const {TextArea} = Input;

interface MessageFormProps {
    title?: string
    message: string
}

const MessageForm: React.FC<MessageFormProps> = (props) => {
    const classes = classNames("db-message-form")
    const {title, message} = props

    return (
        <div className={classes}>
            {
                title && (
                    <div className="form-title">
                        {title}
                    </div>
                )
            }
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        colon={false}
                        name={message}
                        rules={[{required: true, message: "Lütfen Mesajınızı giriniz!"}]}
                    >
                        <TextArea placeholder="Mesajınız" rows={6}/>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default MessageForm;