import {FormInstance, notification} from "antd";
import {ApiHelperType, FormHelperType} from "../../helpers/types";

export const handleFinish = async (
    form: FormInstance,
    setLoad: (loading: boolean) => void,
    formType: number,
    addForm: ApiHelperType["addForm"],
    writeFormErrorsOnFail: FormHelperType["writeFormErrorsOnFail"],
    resetFormErrorsOnSuccess: FormHelperType["resetFormErrorsOnSuccess"]
) => {
    try {
        setLoad(true);
        const values = await form.validateFields();
        const formData = {
            ...values,
        };
        formData.tip = formType;
        formData.dogum_tarihi = values.dogum_tarihi ? values.dogum_tarihi.format("YYYY-MM-DD") : null;
        const response = await addForm(formData);
        if (!response?.Message) {
            resetFormErrorsOnSuccess(form);
            notification.success({
                message: response.Message,
            });
            resetFormErrorsOnSuccess(form);
        } else {
            writeFormErrorsOnFail(response, form);
        }
    } catch (e) {
        notification.error({
            message: 'Lütfen tüm bilgileri eksiksiz doldurunuz.',
        });
    } finally {
        setTimeout(() => {
            setLoad(false);
        }, 1500);
    }
};

export const handleContactFinish = async (
    form: FormInstance,
    setLoad: (loading: boolean) => void,
    addContactForm: ApiHelperType["addForm"],
    writeFormErrorsOnFail: FormHelperType["writeFormErrorsOnFail"],
    resetFormErrorsOnSuccess: FormHelperType["resetFormErrorsOnSuccess"]
) => {
    try {
        setLoad(true);
        const values = await form.validateFields();
        const formData = {
            ...values,
        };
        const response = await addContactForm(formData);
        if (!response?.Message) {
            resetFormErrorsOnSuccess(form);
            notification.success({
                message: response.Message,
            });
        } else {
            writeFormErrorsOnFail(response, form);
        }
    } catch (ex) {
        notification.error({
            message: 'Lütfen tüm bilgileri eksiksiz doldurunuz.',
        });
    } finally {
        setTimeout(() => {
            setLoad(false);
        }, 1500);
    }
};

export const handleFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
};
