import React, {useEffect, useState} from 'react';
import './VCardPage.scss'
import classNames from "classnames";
import {useAppContext} from "../../context/ContextProvider";
import phoneIcon from "../../images/phone.svg";
import mailIcon from "../../images/mail.svg";
import {Button} from "antd";
import {FolderFilled, GlobalOutlined, UserOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";


const VCardPage = () => {
    const classes = classNames('db-vcard-page')
    const {setTempValue} = useAppContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const hash = searchParams.get('hash');
    const [decryptedData, setDecryptedData] = useState({
        ad: '',
        soyad: '',
        gorev: '',
        telefon: '',
        eposta: ''
    });

    const decodeBase64 = (encodedText: string) => {
        try {
            return decodeURIComponent(escape(window.atob(encodedText)));
        } catch (error) {
            console.error("Decode işlemi sırasında hata:", error);
            return null;
        }
    };

    const parseKeyValueString = (str: string) => {
        const keyValuePairs = str.split(', ').map(pair => pair.split('='));
        const parsedObj: Record<string, string> = {};
        keyValuePairs.forEach(([key, value]) => {
            parsedObj[key] = value;
        });
        return parsedObj;
    };

    useEffect(() => {
        if (hash) {
            const decodedText = decodeBase64(hash);
            if (decodedText) {
                const decryptedObj = parseKeyValueString(decodedText);
                setDecryptedData({
                    ad: decryptedObj.ad,
                    soyad: decryptedObj.soyad,
                    gorev: decryptedObj.gorev,
                    telefon: decryptedObj.telefon,
                    eposta: decryptedObj.eposta
                });
            }
        }
        setTempValue('');
    }, [hash]);


    const createVCard = () => {
        return `
BEGIN:VCARD
VERSION:3.0
FN:${decryptedData.ad} ${decryptedData.soyad}
TITLE;CHARSET=UTF-8:${decryptedData.gorev}
TEL:${decryptedData.telefon}
EMAIL:${decryptedData.eposta}
URL:https://www.derinbilgi.com.tr
END:VCARD
        `;
    };

    const downloadVCard = () => {
        const vCardData = createVCard();
        const blob = new Blob([vCardData], {type: "text/vcard"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${decryptedData.ad}_${decryptedData.soyad}_contact.vcf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={classes}>
            <div className="vcard">
                <div className="vcard-text">
                    <div className="vcard-text-name">
                        {decryptedData.ad} {decryptedData.soyad}
                    </div>
                    <div className="vcard-text-title">
                        {decryptedData.gorev}
                    </div>
                </div>
                <div className="vcard-icons">
                    <a href={`tel:${decryptedData.telefon}`}>
                        <div className="vcard-icons-item">
                            <img src={phoneIcon} alt="phone-icon"/>
                        </div>
                    </a>
                    <a href={`mailto:${decryptedData.eposta}`}>
                        <div className="vcard-icons-item">
                            <img src={mailIcon} alt="mail-icon"/>
                        </div>
                    </a>
                </div>
                <div className="vcard-add-contact">
                    <Button
                        type="link"
                        block
                        className="vcard-add-contact-button"
                        onClick={downloadVCard}
                    >
                        <UserOutlined/>
                        <p>Kişilere Ekle</p>
                    </Button>
                </div>
                <div className="vcard-contact-info">
                    <div className="vcard-contact-info-item">
                        <div className="vcard-contact-info-item-icon">
                            <img src={phoneIcon} alt="phone-icon"/>
                        </div>
                        <div>
                            <div className="vcard-contact-info-item-subtitle">İş Telefonu</div>
                            <div>{decryptedData.telefon}</div>
                        </div>
                    </div>
                    <div className="vcard-contact-info-item">
                        <div className="vcard-contact-info-item-icon">
                            <img src={mailIcon} alt="mail-icon"/>
                        </div>
                        <div>
                            <div className="vcard-contact-info-item-subtitle">E-mail</div>
                            <div>{decryptedData.eposta}</div>
                        </div>
                    </div>
                    <div className="vcard-contact-info-item">
                        <div className="vcard-contact-info-item-icon">
                            <GlobalOutlined/>
                        </div>
                        <div>
                            <div className="vcard-contact-info-item-subtitle">Derin Bilgi</div>
                            <div>www.derinbilgi.com.tr</div>
                        </div>
                    </div>
                    <div className="vcard-contact-info-item">
                        <div className="vcard-contact-info-item-icon">
                            <FolderFilled/>
                        </div>
                        <div>
                            <div className="vcard-contact-info-item-subtitle">Derin Bilgi Sistem</div>
                            <div>{decryptedData.gorev}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VCardPage;
