import React, {useState} from 'react';
import './Product.scss'
import classNames from "classnames";
import {ReactComponent as ProductButtonHover} from "../../images/productButtonHover.svg";
import {ReactComponent as ProductButton} from "../../images/productButton.svg";
import {Link} from "react-router-dom";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import {ProductType} from "../../helpers/types";


interface ProductProps {
    product: ProductType
}

const Product: React.FC<ProductProps> = (props) => {
    const {product} = props

    const classes = classNames("db-product")
    const [isHovered, setIsHovered] = useState(false);
    const isMobile = useIsSmallScreen(1024)

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className={classes}>
            <div
                className={`product-card`}
                style={{
                    backgroundColor: !isMobile && isHovered ? product.urun_rengi : "black",
                    boxShadow: !isMobile && isHovered ? `0px 4px 10px 0px ${product.urun_rengi}` : '',
                    border: `1px solid ${product.urun_rengi}`,
                    transition: '0.5s all'
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="product-card-image">
                    <div>
                        <img
                            src={`https://derinbilgi-api.derinpos.com/${product.ikon}`}
                            alt={product.urun_kisa_adi}
                            style={{
                                border: !isMobile && isHovered ? '1px solid black' : '',
                                borderRadius: 12,
                                transition: '0.5s all'
                            }}
                        />
                    </div>
                    <p>
                        {product.urun_adi}
                    </p>
                </div>
                <div className="product-card-description" style={{color: !isMobile && isHovered ? "black" : "white"}}>
                    {product.aciklama}
                </div>
                <div className="product-card-detail-button">
                    <Link to={`${ApplicationRoutes.productDetail}/${product.slug}`}>
                        <span>Keşfet</span>
                        {!isMobile && isHovered ? <ProductButtonHover/> : <ProductButton/>}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Product;