import React from 'react';
import './NewsSlider.scss'
import classNames from "classnames";
import arrowButtonOutlined from '../../../../images/arrowButtonOutlined.svg'
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../../../consts/ApplicationConst";
import {NewsType} from "../../../../helpers/types";
import {truncateTextWithLine} from "../../../../helpers/Formatting";
import NoImage from '../../../../images/noImage.png'


interface NewsSliderProps {
    news: NewsType
}

const NewsSlider: React.FC<NewsSliderProps> = (props) => {
    const {news} = props
    const classes = classNames('db-news-slider')

    return (
        <div className={classes}>
            <div className="news-card">
                <div className="news-card-text">
                    <p className="news-card-text-title" style={truncateTextWithLine(4)}>
                        {news.baslik}
                    </p>
                </div>
                <div className="news-card-content">
                    <div className="news-card-content-image">
                        <img
                            src={news.fotograf ? `https://derinbilgi-api.derinpos.com/${news.fotograf}` : NoImage}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                const target = e.target as HTMLImageElement;
                                target.src = NoImage!;
                            }}
                            alt="news-img"
                        />
                    </div>
                    <div className="news-card-content-detail">
                        <div className="news-card-content-detail-text" style={truncateTextWithLine(3)}>
                            {news.alt_baslik}
                        </div>
                        <Link to={`${ApplicationRoutes.newsDetail}/${news.slug}`}>
                            <div className="news-card-content-detail-button">
                                <img src={arrowButtonOutlined} alt="detail-button"/>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsSlider;