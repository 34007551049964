import React from 'react';
import "./FormSendButton.scss"
import {Button} from "antd";
import SendForm from "../../../../images/SendForm.svg";
import classNames from "classnames";

const FormSendButton = () => {
    const classes = classNames("db-form-send-button")

    return (
        <div className={classes}>
            <div className="send-form-button">
                <Button type={"link"} htmlType={"submit"}>
                    <img src={SendForm} alt=""/>
                </Button>
            </div>
        </div>
    );
};

export default FormSendButton;