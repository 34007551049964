import React, {useEffect} from 'react';
import './DerinbilgiInterestsPage.scss'
import classNames from "classnames";
import Icon1 from "../../images/DerinBilgiIntroduceIcon1.png";
import Icon2 from "../../images/DerinBilgiIntroduceIcon2.png";
import Icon3 from "../../images/DerinBilgiIntroduceIcon3.png";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import {useAppContext} from "../../context/ContextProvider";


interface DerinBilgiInterestsType {
    title: string,
    description: string,
    image: string,
}

const DerinBilgiIntroduceInterestData: DerinBilgiInterestsType[] = [
    {
        title: 'Kavramsal ve Yönetimsel',
        description: 'Perekande iş süreçleriCRM, Kampanya Yönetimi,Ölçülebilir ve verimli proje yönetimie-Dönüşüm, e-Fatura, e-ArşivB2B, B2C, C2C çözümleriİş zekası, Karnelerle yönetimDepo Yönetim SistemleriPlanogram, Mağaza Raf Yönetimi',
        image: Icon1,
    },
    {
        title: 'Veri Bütünleştirme',
        description: 'POS yazılımları entgerasyonu,IBM, NCR, Wincor-Nixdorf aktarımı EFT POS ve banka entegrasyonları Terazi aktarımlar Elektronik Raf Etiket SistemleriFiyat Gör Sistemleri',
        image: Icon2,
    },
    {
        title: 'Teknoloji',
        description: 'Microsoft sunucu ailesi,MS SQL Server ve yazılım geliştirme Microsoft Sharepoint,Microsoft LyncMobil Android, Iphone, Blackberry, Windows PhoneEl terminalleri Ses-Yazı çevrimleri ve algılamaGömülü yazılımlar.',
        image: Icon3,
    },
]

const DerinbilgiInterestsPage = () => {
    const classes = classNames('db-derinbilgi-interests-page')
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div className={classes}>
            <PageHelmet
                title={'İlgi Alanlarımız'}
                description={'İlgi Alanlarımız'}
                keywords={'ilgi alanlarimiz, interests, hakkimizda, about, derinbilgi, company'}
                noIndex={false}
            />
            <div className="derinbilgi-interests-page">
                <div className="header-title">
                    İlgi Alanlarımız
                </div>
                <div className="derinbilgi-interests-page-cards">
                    {DerinBilgiIntroduceInterestData.map((data, index) => (
                        <div className="derinbilgi-interests-page-cards-item" key={index}>
                            <div className="derinbilgi-interests-page-cards-item-image">
                                <img src={data.image} alt="card-img"/>
                            </div>
                            <div className="derinbilgi-interests-page-cards-item-title">
                                {data.title}
                            </div>
                            <div className="derinbilgi-interests-page-cards-item-description">
                                {data.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DerinbilgiInterestsPage;