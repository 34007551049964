import React from 'react';
import './SuccessStoriesSlider.scss'
import {Button} from "antd";
import classNames from "classnames";
import arrowButton from "../../../../images/arrowButton.svg"
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../../../consts/ApplicationConst";
import {ReviewListType} from "../../../../helpers/types";
import {useIsSmallScreen} from "../../../../hooks/useIsSmallScreen";
import {truncateTextWithLine} from "../../../../helpers/Formatting";
import NoImage from "../../../../images/noImage.png";


interface SuccessStoriesSliderProps {
    successStory: ReviewListType,
}

const SuccessStoriesSlider: React.FC<SuccessStoriesSliderProps> = (props) => {
    const {successStory} = props
    const classes = classNames('db-success-story-slider')
    const isMobile = useIsSmallScreen(768)

    return (
        <div className={classes}>
            <div className="success-story">
                <div className="success-story-text">
                    <p className="success-story-text-title">
                        {successStory.baslik}
                    </p>
                </div>
                <div className="success-story-image">
                    <img
                        src={successStory.fotograf ? `https://derinbilgi-api.derinpos.com/${successStory.fotograf}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt="news-img"
                    />
                </div>
                <div className="success-story-description">
                    <div className="success-story-description-text">
                        <div className="success-story-description-text-title" style={truncateTextWithLine(3)}>
                            {successStory.alt_baslik}
                        </div>
                        <Link
                            to={`${ApplicationRoutes.successStoryDetail}/${successStory.slug}`}
                            className="success-story-description-button"
                        >
                            <div>
                                <Button type={"link"}>
                                    <img src={arrowButton} alt=""/>
                                </Button>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessStoriesSlider;