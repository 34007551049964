import React from 'react';

interface DynamicArrowButtonProps {
    fillColor?: string;
}

const DynamicArrowButton: React.FC<DynamicArrowButtonProps> = ({fillColor = '#8EE558'}) => (
    <svg width="40" height="40" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.8362" cy="22" r="21.8362" fill={fillColor}/>
        <path
            d="M17.9041 31.3931C17.9997 31.4949 18.1296 31.5526 18.2654 31.5534C18.3328 31.5541 18.3995 31.5402 18.4617 31.5127C18.5238 31.4851 18.5799 31.4444 18.6266 31.3931L27.1445 22.3884C27.1924 22.3384 27.2303 22.279 27.2563 22.2134C27.2822 22.1479 27.2955 22.0775 27.2955 22.0065C27.2955 21.9354 27.2822 21.8651 27.2563 21.7995C27.2303 21.734 27.1924 21.6745 27.1445 21.6246L18.6266 12.618C18.5798 12.5651 18.5234 12.5226 18.4607 12.4932C18.398 12.4638 18.3304 12.4481 18.2619 12.4469C18.1933 12.4456 18.1252 12.459 18.0617 12.4862C17.9981 12.5134 17.9404 12.5538 17.8919 12.605C17.8434 12.6563 17.8052 12.7173 17.7795 12.7845C17.7538 12.8517 17.7411 12.9237 17.7423 12.9961C17.7434 13.0686 17.7583 13.1401 17.7861 13.2063C17.8139 13.2726 17.8541 13.3322 17.9041 13.3818L26.0608 22.0065L17.9041 30.6293C17.8565 30.6793 17.8187 30.7388 17.7929 30.8044C17.7672 30.8699 17.7539 30.9402 17.7539 31.0112C17.7539 31.0822 17.7672 31.1524 17.7929 31.218C17.8187 31.2835 17.8565 31.343 17.9041 31.3931Z"
            fill="#141414"
            stroke="#141414"
            strokeWidth="1"
        />
    </svg>
);

export default DynamicArrowButton;
