import React, {useEffect, useState} from 'react';
import './HomeDescription.scss';
import Home1 from '../../../images/Home1.png';
import Home2 from '../../../images/ProductDetail9.png';
import Home3 from '../../../images/ProductDetail2.png';
import classNames from 'classnames';
import OptionArrow from "../../option-arrow/OptionArrow";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";

interface OptionArrowData {
    label: string;
}

interface ImageContent {
    text: string;
    image?: string;
}

const optionArrowData: OptionArrowData[] = [
    {
        label: 'Operasyon'
    },
    {
        label: 'Muhasebe'
    },
    {
        label: 'Mükemmellik'
    },
];

const imageContents: ImageContent[] = [
    {
        text:
            'Ürün yönetimi, kategori yönetimi, stok yönetimi, fiyat yönetimi, sipariş yönetimi, otomatik sipariş sistemleri, fatura kontrol, fiyat farkları ve ciro prim yönetiminden oluşan satın alma yönetimi Mağaza yönetimi, etiketleme, el terminali yazılımı, pos entegrasyonlarından oluşan Satış Operasyon Yönetimi',
        image: Home1,
    },
    {
        text: 'Mağaza yönetimi, etiketleme, el terminali yazılımı, pos entegrasyonlarından oluşan Satış Operasyon Yönetimi',
        image: Home2
    },
    {
        text: 'Ürün yönetimi, kategori yönetimi, stok yönetimi, fiyat yönetimi, sipariş yönetimi, otomatik sipariş sistemleri, fatura kontrol, fiyat farkları ve ciro prim yönetiminden oluşan satın alma yönetimi',
        image: Home3
    },
];

const HomeDescription: React.FC = () => {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const classes = classNames('db-home-description');

    const isMediumScreen = useIsSmallScreen(1024);

    useEffect(() => {
        setSelectedIndex(selectedOptionIndex);
    }, [selectedOptionIndex]);

    return (
        <div className={classes}>
            <div className="home-description">
                {
                    !isMediumScreen
                        ? (
                            <>
                                <div className="home-description-content">
                                    <div className="header-subtitle">
                                        Derinsis ile zincir mağaza yönetiminde derinleşin!
                                    </div>
                                    <div className="home-description-content-description">
                                        DerinSİS markasıyla perakende sektörüne özel bir ERP sistemi sunan Derin Bilgi;
                                        market,
                                        kırtasiye, yapımarket, kozmetik gibi, büyüme ve kurumsallaşmada mağaza
                                        otomasyonun en kritik
                                        olduğu sektörlerde, yoğun proje deneyimiyle mükemmel bir hizmet veriyor. Özgün
                                        yaklaşımımızla yazılım çözümleri sağladığımız başlıca alanlar;
                                    </div>
                                    <div className="home-description-content-options">
                                        {optionArrowData.map((option, index) => (
                                            <OptionArrow
                                                key={index}
                                                label={option.label}
                                                index={index}
                                                setSelectedOptionIndex={setSelectedOptionIndex}
                                                selectedIndex={selectedIndex}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="home-description-image">
                                    <img
                                        src={imageContents[selectedOptionIndex].image}
                                        alt=""
                                    />
                                    <div className="home-description-image-text">
                                        {imageContents[selectedOptionIndex].text}
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <div className="home-description-content">
                                <div className="header-subtitle">
                                    Derinsis ile zincir mağaza yönetiminde derinleşin!
                                </div>
                                <div className="home-description-content-description">
                                    DerinSİS markasıyla perakende sektörüne özel bir ERP sistemi sunan Derin Bilgi;
                                    market,
                                    kırtasiye, yapımarket, kozmetik gibi, büyüme ve kurumsallaşmada mağaza
                                    otomasyonun en kritik
                                    olduğu sektörlerde, yoğun proje deneyimiyle mükemmel bir hizmet veriyor. Özgün
                                    yaklaşımımızla yazılım çözümleri sağladığımız başlıca alanlar;
                                </div>
                                <div className="home-description-content-options">
                                    {optionArrowData.map((option, index) => (
                                        <>
                                            <OptionArrow
                                                key={index}
                                                label={option.label}
                                                index={index}
                                                setSelectedOptionIndex={setSelectedOptionIndex}
                                                selectedIndex={selectedIndex}
                                            />
                                            <div
                                                className="home-description-image"
                                                style={{
                                                    display: selectedOptionIndex === index
                                                        ? 'block'
                                                        : 'none'
                                                }}
                                            >
                                                <img
                                                    src={imageContents[selectedOptionIndex].image}
                                                    alt=""
                                                />
                                                <div className="home-description-image-text">
                                                    {imageContents[selectedOptionIndex].text}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default HomeDescription;
