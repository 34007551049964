import React, {useEffect, useState} from 'react';
import './ProductsPage.scss'
import {useAppContext} from "../../context/ContextProvider";
import classNames from "classnames";
import SlideButtons from "../../components/slide-buttons/SlideButtons";
import Product from "../../components/product/Product";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ProductType} from "../../helpers/types";
import {Spin} from "antd";


const ProductsPage = () => {
    const classes = classNames('db-products-page')
    const isSmallScreen = useIsSmallScreen(768)
    const isMediumScreen = useIsSmallScreen(1024)
    const {setTempValue} = useAppContext();
    const {getProducts} = useApiHelper();
    const [dataProducts, setDataProducts] = useState<ProductType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getProducts();
            if (response?.urunlerlar) {
                setDataProducts(response.urunlerlar);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        setTempValue("");
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Ürünler'}
                description={'Ürünler'}
                keywords={'urunler, products'}
                noIndex={false}
            />
            <div className="header-title">
                Birbirine entegre Dijital ekosistem ürünleri ile kusursuz büyüme
            </div>
            {
                !isSmallScreen
                    ? (
                        <Swiper
                            modules={[Pagination, Navigation]}
                            grabCursor={true}
                            loop={false}
                            spaceBetween={12}
                            slidesPerView={isMediumScreen ? 2 : 3}
                        >
                            <SlideButtons/>
                            {dataProducts && dataProducts
                                .filter(product => product.sira)
                                .map((product, index) => (
                                    <SwiperSlide key={index}>
                                        <Product product={product}/>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    )
                    : (
                        <div>
                            {dataProducts?.map((product, index) => (
                                <Product product={product} key={index}/>
                            ))}
                        </div>
                    )
            }
        </div>
    );
};

export default ProductsPage;