import React, {useEffect} from 'react';
import '../Layout.scss'
import LearnDerinsisForm from "../../components/forms/learn-derinsis-form/LearnDerinsisForm";
import {useAppContext} from "../../context/ContextProvider";
import FormInfo from "../../components/forms/form-info/FormInfo";
import CertificationFormImage from "../../images/CertificationFormImage.png";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const LearnDerinsisFormPage = () => {
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div>
            <PageHelmet
                title={'Derinsis Öğrenmek İstiyorum'}
                description={'Derinsis Öğrenmek İstiyorum'}
                keywords={'derinsis ogrenmek istiyorum formu, learn derinsis form'}
                noIndex={false}
            />
            <FormInfo
                title={"DerinSİS öğrenmek istiyorum"}
                detail={"DerinSİS kullanan müşterilerimizde istihdam etmek üzere, ihtiyaç duyulan bölümlere göre ERP eğitimleri düzenlemekteyiz. Takvimi belirlendikçe duyurusu yapılarak sınıflar oluşturulmaktadır. İletişim bilgilerinizi ve ilgilendiğiniz alanları paylaşmanız durumunda, bu eğitimlere katılabilirsiniz."}
                image={CertificationFormImage}
            />
            <LearnDerinsisForm/>
        </div>
    );
};

export default LearnDerinsisFormPage;