import React, {useEffect, useState} from 'react';
import './RemediesSliders.scss'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import classNames from "classnames";
import RemedySlider from "./remedy-slider/RemedySlider";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import useApiHelper from "../../../hooks/useApiHelper";
import {RemediesDetail} from "../../../helpers/types";


const RemediesSliders = () => {
    const classes = classNames('db-remedies-sliders');
    const isSmallScreen = useIsSmallScreen(768);
    const isMediumScreen = useIsSmallScreen(1024);
    const {getRemediesDetail} = useApiHelper();
    const [dataRemedies, setDataRemedies] = useState<RemediesDetail[]>([]);

    const getData = async () => {
        try {
            const response = await getRemediesDetail();
            if (response.Cozumlerimiz) {
                setDataRemedies(response.Cozumlerimiz);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
        }
    }
    useEffect(() => {
        getData();
    }, []);


    return (
        <div className={classes}>
            <div>
                <div className="header-subtitle">
                    Çözümler
                </div>
                <Swiper
                    grabCursor={true}
                    loop={true}
                    pagination={{clickable: true}}
                    spaceBetween={32}
                    slidesPerView={isSmallScreen ? 1 : isMediumScreen ? 2 : 3}
                >
                    {dataRemedies.map((remedy, index) => (
                        <SwiperSlide key={index}>
                            <RemedySlider remedy={remedy}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default RemediesSliders;