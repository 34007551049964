import React from "react";
import {ApplicationRoutes} from "../consts/ApplicationConst";
import {MenuItemsType} from "../components/toolbar/type";

export const MenuItems: MenuItemsType = [
    {
        label: "Anasayfa",
        key: 0,
        url: ApplicationRoutes.home,
    },
    {
        label: "Ürünler",
        key: 5,
        url: ApplicationRoutes.products,
    },
    {
        label: <div>Bizi Tanıyın</div>,
        key: 1,
        children: [
            {
                label: "Derin Bilgi",
                key: 11,
                url: ApplicationRoutes.about,
            }, 
            {
                label: "İlgi Alanlarımız",
                key: 12,
                url: ApplicationRoutes.ourInterests,
            },
            {
                label: "Haberler",
                key: 13,
                url: ApplicationRoutes.news,
            },
            {
                label: "İlanlarımız",
                key: 14,
                url: ApplicationRoutes.adverts,
            },
            {
                label: "Microsoft İş Ortaklığı",
                key: 15,
                url: ApplicationRoutes.microsoftPartner,
            },
        ],
    },
    {
        label: <div>İnceleyin</div>,
        key: 2,
        children: [
            {
                label: "Çözümlerimiz",
                key: 21,
                url: ApplicationRoutes.remedies,
            },
            {
                label: "Başarı Hikayeleri",
                key: 22,
                url: ApplicationRoutes.successStory,
            },
            {
                label: "Merak Ettikleriniz",
                key: 23,
                url: ApplicationRoutes.curiosities,
            },
        ],
    },
    {
        label: <div>Bizi Sorun</div>,
        key: 3,
        children: [
            {
                label: "Zincir Mağazalar",
                key: 31,
                url: ApplicationRoutes.chainStores
            },
            {
                label: "Kırtasiyeler",
                key: 32,
                url: ApplicationRoutes.stationery
            },
            {
                label: "Toptan/Dağıtım Şirketleri",
                key: 33,
                url: ApplicationRoutes.wholesaleDistribution
            },
            {
                label: "e-Ticaret Projeleri",
                key: 34,
                url: ApplicationRoutes.ecommerceProjects
            },
            {
                label: "Lojistik Şirketleri",
                key: 35,
                url: ApplicationRoutes.logisticsCompanies
            },
            {
                label: "Topla.io Projeleri",
                key: 36,
                url: ApplicationRoutes.toplaioProjects
            },
        ],
    },
    {
        label: <div>İletişim</div>,
        key: 4,
        children: [
            {
                label: "İletişim",
                key: 41,
                url: ApplicationRoutes.contact,
            },
            {
                label: "Sertifikasyon Başvurusu",
                key: 42,
                url: ApplicationRoutes.certificationForm
            },
            {
                label: "Derinsis Öğrenmek İstiyorum",
                key: 43,
                url: ApplicationRoutes.learnDerinsisForm
            },
            {
                label: "İnsan Kaynakları Formu",
                key: 44,
                url: ApplicationRoutes.humanResourceForm
            },
        ],
    },
];