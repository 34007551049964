import React, {useState} from 'react';
import "./CertificationForm.scss"
import classNames from "classnames";
import {Form, Spin} from "antd";
import PersonalInformationForm from "../form-parts/personal-information-form/PersonalInformationForm";
import DerinSisInformationForm from "../form-parts/derinsis-information-form/DerinSisInformationForm";
import ContactInformationForm from "../form-parts/contact-information-form/ContactInformationForm";
import MessageForm from "../form-parts/message-form/MessageForm";
import FormSendButton from "../form-parts/form-send-button/FormSendButton";
import useApiHelper from "../../../hooks/useApiHelper";
import useFormHelper from "../../../hooks/useFormHelper";
import {handleFinish, handleFinishFailed} from "../formHandlers";
import {formatPhoneNumber} from "../../../helpers/Formatting";


const CertificationForm = () => {
    const classes = classNames("db-certification-form")
    const [form] = Form.useForm();
    const {addForm} = useApiHelper()
    const {writeFormErrorsOnFail, resetFormErrorsOnSuccess} = useFormHelper();
    const [load, setLoad] = useState<boolean>(false);
    const onFinish = () => handleFinish(form, setLoad, 1, addForm, writeFormErrorsOnFail, resetFormErrorsOnSuccess);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        form.setFieldsValue({telefon_no: formatPhoneNumber(value)});
    };

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <div className="certification-form">
                <Form
                    form={form}
                    name="certification-form"
                    wrapperCol={{span: 24}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={handleFinishFailed}
                    autoComplete="off"
                >
                    <div>
                        <PersonalInformationForm
                            formName="derinsis-sertifikasyon-egitimine-katilmak-istiyorum"
                            name='isim'
                            education='egitim'
                            dateOfBirth='dogum_tarihi'
                        />
                    </div>
                    <div>
                        <DerinSisInformationForm
                            formName="derinsis-sertifikasyon-egitimine-katilmak-istiyorum"
                            companyName="derinsis_musteri"
                            derinsisDepartment="derinsis_departman"
                        />
                    </div>
                    <div>
                        <ContactInformationForm
                            email="eposta"
                            phoneNumber="telefon_no"
                            city="il"
                            district="ilce"
                            handlePhoneChange={handlePhoneChange}
                        />
                    </div>
                    <div>
                        <MessageForm title="Mesajınız" message="mesaj"/>
                    </div>

                    <div>
                        <FormSendButton/>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default CertificationForm;