import React, {useEffect} from 'react';
import '../Layout.scss'
import CertificationForm from "../../components/forms/certification-form/CertificationForm";
import {useAppContext} from "../../context/ContextProvider";
import CertificationFormImage from "../../images/CertificationFormImage.png";
import FormInfo from "../../components/forms/form-info/FormInfo";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const CertificationFormPage = () => {
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div>
            <PageHelmet
                title={'Sertifikasyon Başvurusu'}
                description={'Sertifikasyon Formu'}
                keywords={'sertifikasyon formu, certification form'}
                noIndex={false}
            />
            <FormInfo
                title={"Sertifikasyon eğitimi almak istiyorum"}
                detail={"DerinSİS kullanan müşterilerimizde görev alan ve almış olan kullanıcıların, belirlediğimiz standartlarda bilgi sahibi olmalarını teşvik etmek ve bilgi düzeylerini belirlemek üzere sertifikasyon çalışmaları yapmaktayız. İletişim bilgilerinizi, uzmanlık alanınızı ve deneyim durumunuzu belirtir şekilde bizimle paylaşırsanız, sertifikasyon için bir takvim oluşturup sizlere ulaşacağız."}
                image={CertificationFormImage}
            />
            <CertificationForm/>
        </div>
    );
};

export default CertificationFormPage;