import classNames from "classnames";
import './RemediesListItem.scss'
import arrowButton from '../../images/arrowButton.svg'
import {Link} from "react-router-dom";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import {Remedies} from "../../helpers/types";
import React from "react";
import NoImage from "../../images/noImage.png";


export interface RemediesListItemOptionType {
    label: string,
    url: string,
}

interface RemediesListItemType {
    remediesListItem: Remedies
}

const RemediesListItem: React.FC<RemediesListItemType> = (props) => {
    const {remediesListItem} = props
    const classes = classNames('db-remedies-list-item')

    return (
        <div className={classes}>
            <div className="remedies-list-item-card"
                 style={{
                     backgroundImage: `url(https://derinbilgi-api.derinpos.com/${remediesListItem.resim})`
                 }}
            >
                {/*responsive olması için 1024px den sonra background image kaldırılıp text in üstüne image eklendi */}
                <div className="remedies-list-item-card-image">
                    <img
                        src={remediesListItem.mobil_resim ? `https://derinbilgi-api.derinpos.com/${remediesListItem.mobil_resim}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        className="card-img" alt=""/>
                </div>
                <div className="remedies-list-item-card-text">
                    <div className="remedies-list-item-card-text-title">
                        {remediesListItem.baslik}
                    </div>
                    <div className="remedies-list-item-card-text-subtitle">
                        {remediesListItem.alt_baslik}
                    </div>
                    {remediesListItem.detaylar.map((option, index) => (
                        <div
                            key={index}
                        >
                            <Link to={`${ApplicationRoutes.remediesDetail}/${option.slug}`}>
                                <div className="remedies-list-item-card-text-options">
                                    <img src={arrowButton} alt="detail-button"/>
                                    <div className="remedies-list-item-card-text-options-label">
                                        {option.baslik}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RemediesListItem;