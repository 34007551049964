import classNames from 'classnames';
import React from 'react';
import './CenteredLayout.scss';

interface CenteredLayoutProps {
    children: React.ReactNode;
    className?: string;
}

const CenteredLayout: React.FC<CenteredLayoutProps> = (props) => {
    const { children, className } = props;

    const classes = classNames('db-centered-layout', className);

    return <div className={classes}>{children}</div>;
};

export default CenteredLayout;
