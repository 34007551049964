import React, {useState} from "react";
import "./ContactForm.scss";
import locationIcon from "../../../images/location.svg";
import phoneIcon from "../../../images/phone.svg";
import mailIcon from "../../../images/mail.svg";
import classNames from "classnames";
import {Col, Form, Input, Row, Spin} from "antd";
import MessageForm from "../form-parts/message-form/MessageForm";
import FormSendButton from "../form-parts/form-send-button/FormSendButton";
import useApiHelper from "../../../hooks/useApiHelper";
import useFormHelper from "../../../hooks/useFormHelper";
import {handleContactFinish, handleFinishFailed} from "../formHandlers";
import {formatPhoneNumber} from "../../../helpers/Formatting";

interface ContactTypes {
    pageTitle: string;
    title: string;
    subTitle: string;
    location: string;
    phoneNumber: string;
    email: string;
    mapTag: string;
}

const ContactForm: React.FC<ContactTypes> = (props) => {
    const classes = classNames("db-contact-form");
    const {pageTitle, title, subTitle, location, phoneNumber, email, mapTag} = props;
    const [form] = Form.useForm();
    const {addContactForm} = useApiHelper();
    const {writeFormErrorsOnFail, resetFormErrorsOnSuccess} = useFormHelper();
    const [load, setLoad] = useState<boolean>(false);
    const onFinish = () => handleContactFinish(form, setLoad, addContactForm, writeFormErrorsOnFail, resetFormErrorsOnSuccess);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        form.setFieldsValue({telefonno: formatPhoneNumber(value)});
    };

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <div className="contact">
                <Row className="contact-info" gutter={[32, 32]}>
                    <Col xs={24} lg={12} className="contact-info-left">
                        <div className="header-title">{pageTitle}</div>
                        <div className="contact-info-left-company-name">
                            <div className="contact-info-left-company-name-title">
                                {title}
                            </div>
                            <div className="contact-info-left-company-name-subtitle">
                                {subTitle}
                            </div>
                        </div>
                        <Row className="contact-info-left-icons" gutter={[32, 32]}>
                            <Col xs={24} sm={12} className="contact-info-left-icons-content">
                                <img src={locationIcon} alt="location-icon"/>
                                <div>
                                    {location}
                                </div>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Row gutter={[32, 32]}>
                                    <Col className="contact-info-left-icons-content">
                                        <img src={phoneIcon} alt="phone-icon"/>
                                        <a href={`tel:${phoneNumber}`}>
                                            {phoneNumber}
                                        </a>
                                    </Col>
                                    <Col className="contact-info-left-icons-content">
                                        <img src={mailIcon} alt="mail-icon"/>
                                        <a href={`mailto:${email}`}>
                                            {email}
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={12} className="contact-info-right">
                        <iframe
                            src={mapTag}
                            className="contact-info-right-map"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="company-location"
                        />
                    </Col>
                </Row>
                <div className="contact-form">
                    <div className="contact-form-title">Bize Yazın</div>
                    <Form
                        form={form}
                        name="contact-form"
                        wrapperCol={{span: 24}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={handleFinishFailed}
                        autoComplete="off"
                    >
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    colon={false}
                                    name="isim"
                                    rules={[{required: true, message: "Lütfen İsminizi giriniz!"}]}
                                >
                                    <Input placeholder="İsim"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    colon={false}
                                    name="konu"
                                    rules={[{required: true, message: "Lütfen Konu giriniz!"}]}
                                >
                                    <Input placeholder="Konu"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    colon={false}
                                    name="eposta"
                                    rules={[
                                        {required: true, message: "Lütfen E-Mail adresinizi giriniz!"},
                                        {type: 'email', message: "Lütfen geçerli bir E-Mail adresi giriniz!"}
                                    ]}
                                >
                                    <Input placeholder="E-Mail"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    colon={false}
                                    name="telefonno"
                                    rules={[
                                        {required: true, message: "Lütfen telefon numaranızı giriniz!"},
                                        {
                                            pattern: /^\(\d{3}\) \d{3} \d{2}-\d{2}$/,
                                            message: "Lütfen geçerli bir telefon numarası giriniz! Örn: (555) 555 55-55"
                                        }
                                    ]}
                                >
                                    <Input placeholder="Telefon No (Örn: 5xx xxx xx-xx)" onChange={handlePhoneChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <MessageForm title="Mesajınız" message="yorumlar"/>
                        </div>
                        <div>
                            <FormSendButton/>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
