import React from 'react';
import './SocialMedias.scss'
import classNames from "classnames";
import {Link} from "react-router-dom";
import {ReactComponent as InstagramIcon} from "../../images/instagram.svg";
import {ReactComponent as LinkedinIcon} from "../../images/linkedin.svg";
import {ReactComponent as FacebookIcon} from "../../images/facebook.svg";
import {XOutlined} from "@ant-design/icons";


interface SocialMediasType {
    url: string,
    icon: React.ReactNode
}

const socialMediasData: SocialMediasType[] = [
    {
        url: 'https://www.instagram.com/derinbilgi_/?ref=badge',
        icon: <InstagramIcon/>
    },
    {
        url: 'https://www.linkedin.com/company/derin-bilgi-sistemleri?',
        icon: <LinkedinIcon/>
    },
    {
        url: 'https://www.facebook.com/DerinBilgi',
        icon: <FacebookIcon/>
    },
    {
        url: 'https://x.com/derin__bilgi',
        icon: <XOutlined/>
    },
]

const SocialMedias = () => {
    const classes = classNames('db-social-medias')

    return (
        <div className={classes}>
            {
                socialMediasData.map((item, index) => (
                    <Link
                        to={item.url}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                    >
                        {item.icon}
                    </Link>
                ))
            }
        </div>
    );
};

export default SocialMedias;