import React, {useEffect, useState} from 'react';
import './NewsSliders.scss'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import classNames from "classnames";
import NewsSlider from "./news-slider/NewsSlider";
import SlideButtons from "../../slide-buttons/SlideButtons";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import useApiHelper from "../../../hooks/useApiHelper";
import {NewsType} from "../../../helpers/types";


const NewsSliders = () => {
    const classes = classNames('db-news-sliders');
    const isSmallScreen = useIsSmallScreen(768)
    const isMediumScreen = useIsSmallScreen(1024)
    const {getNews} = useApiHelper();
    const [dataNews, setDataNews] = useState<NewsType[] | undefined>(undefined);
    const getData = async () => {
        try {
            const response = await getNews();
            if (response?.Habers) {
                setDataNews(response.Habers);
            }
        } catch (error) {
            console.error('getData error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={classes}>
            <div className="header-subtitle">
                Haberler
            </div>
            <Swiper
                modules={[Pagination, Navigation]}
                grabCursor={true}
                loop={false}
                pagination={{clickable: true}}
                spaceBetween={32}
                slidesPerView={isSmallScreen ? 1 : isMediumScreen ? 2 : 3}
            >
                {dataNews && dataNews
                    .filter(news => news.aktif)
                    .filter(news => news.guncel_haberler)
                    .map((news, index) => (
                        <SwiperSlide key={index}>
                            <NewsSlider news={news}/>
                        </SwiperSlide>
                    ))}
                {!isSmallScreen && <SlideButtons/>}
            </Swiper>
        </div>
    );
};

export default NewsSliders;