import {
    AdvertsResponse,
    CuriositiesDetailResponse,
    CuriositiesResponse,
    DepartmentsResponse,
    HomeSlidersResponse, NewsDetailResponse, NewsPagingResponse,
    NewsResponse,
    ProductDetailResponse,
    ProductResponse,
    ReferenceStoreDetailResponse,
    ReferenceStoresResponse,
    RemediesDetailResponse,
    RemediesDetailWithSlugResponse,
    RemediesResponse,
    Response,
    SuccessStoriesDetailResponse,
    SuccessStoriesResponse
} from "./types";
import {ContactFormType, FormType} from "../components/forms/types";


class ApiHelper {
    static INSTANCE: ApiHelper | null = null;
    static baseUrl: string =
        process.env.NX_API_URL || 'https://derinbilgi-api.derinpos.com';

    static getInstance(): ApiHelper {
        if (ApiHelper.INSTANCE === null) {
            ApiHelper.INSTANCE = new ApiHelper();
        }
        return ApiHelper.INSTANCE;
    }

    public async get(url: string): Promise<Response<any>> {
        const response = await fetch(ApiHelper.baseUrl + url, {
            method: "GET",
            redirect: "follow"
        });
        const json = await response.json();
        return await this.middlewareResponse(json);
    }

    // Home API
    async getHomeSliders(): Promise<Response<HomeSlidersResponse>> {
        return await this.get('/slaytlar/');
    }

    // News
    async getNews(): Promise<Response<NewsResponse>> {
        return await this.get("/haber-liste");
    }  
    async getNewsPaging(page:number): Promise<Response<NewsPagingResponse>> {
        return await this.get(`/haber-liste/${page}`);
    }
    async getNewsDetail(slug: string): Promise<Response<NewsDetailResponse>> {
        return await this.get(`/haber-liste/detay/${slug}`);
    }

    // Adverts
    async getAdverts(): Promise<Response<AdvertsResponse>> {
        return await this.get('/ilanlar/');
    }

    // Remedies
    async getRemedies(): Promise<Response<RemediesResponse>> {
        return await this.get('/cozumler/cozumler-gruplar/');
    }

    async getRemediesDetailWithSlug(slug: string | undefined): Promise<Response<RemediesDetailWithSlugResponse>> {
        return await this.get(`/cozumler/cozumlerimiz/${slug}`);
    }

    async getRemediesDetail(): Promise<Response<RemediesDetailResponse>> {
        return await this.get(`/cozumler/cozumlerimiz/`);
    }

    // Products
    async getProducts(): Promise<Response<ProductResponse>> {
        return await this.get('/urunler/');
    }

    async getProductDetail(id: string | undefined): Promise<Response<ProductDetailResponse>> {
        return await this.get(`/urunler/detay/${id}`);
    }

    // Success Stories - Details
    async getSuccessStories(): Promise<Response<SuccessStoriesResponse>> {
        return await this.get('/basari-hikayeleri/');
    }

    async getSuccessStoryDetail(slug: string | undefined): Promise<Response<SuccessStoriesDetailResponse>> {
        return await this.get(`/basari-hikayeleri/${slug}`);
    }

    // Curiosities - Details
    async getCuriosities(): Promise<Response<CuriositiesResponse>> {
        return await this.get('/merak-ettikleriniz/');
    }

    async getCuriositiesDetail(slug: string | undefined): Promise<Response<CuriositiesDetailResponse>> {
        return await this.get(`/merak-ettikleriniz/${slug}`);
    }

    // Reference Stores - Details
    async getReferenceStores(): Promise<Response<ReferenceStoresResponse>> {
        return await this.get('/bizi-sorun/');
    }

    async getReferenceStoreDetail(name: string): Promise<Response<ReferenceStoreDetailResponse>> {
        return await this.get(`/bizi-sorun/${name}`);
    }

    // Departments
    async getDepartments(): Promise<Response<DepartmentsResponse>> {
        return await this.get('/departmanlar/');
    }

    // Add Form - Human Resource, Learn Derinsis, Certification
    async addForm(
        form: Partial<FormType>,
    ) {
        let uri = '/ik-formlari/';
        return await this.post(uri, form);
    }

    // Add Contact Form
    async addContactForm(
        form: Partial<ContactFormType>,
    ) {
        let uri = '/iletisim-mesajlari/';
        return await this.post(uri, form);
    }

    private async post(
        url: string,
        data?: any,
    ): Promise<Response<any>> {
        const response = await fetch(ApiHelper.baseUrl + url, {
            method: 'POST',
            redirect: "follow",
            body: data ? JSON.stringify(data) : undefined,
            headers: {
                'Content-Type': 'application/json', // Content-Type header ekleniyor
            },
        });
        const json = await response.json();
        return await this.middlewareResponse(json);
    }

    private async middlewareResponse(responseJson: any) {
        return responseJson;
    }
}

export default ApiHelper;
