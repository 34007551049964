import React from "react";
import "./App.css";
import Layout from "./pages/Layout";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ContactPage from "./pages/contact/ContactPage";
import SuccessStoriesListPage from "./pages/success-stories-list-page/SuccessStoriesListPage";
import NewsPage from "./pages/news-page/NewsPage";
import "./themeConfig/themeConfig.scss";
import NewsDetailPage from "./pages/news-detail-page/NewsDetailPage";
import HomePage from "./pages/home-page/HomePage";
import {ContextProvider} from "./context/ContextProvider";
import SuccessStoriesDetailsPage from "./pages/success-stories-details-page/SuccessStoriesDetailsPage";
import ProductsPage from "./pages/products-page/ProductsPage";
import CertificationFormPage from "./pages/certification-form-page/CertificationFormPage";
import DerinBilgiIntroducePage from "./pages/derinbilgi-introduce-page/DerinBilgiIntroducePage";
import RemediesListPage from "./pages/remedies-list-page/RemediesListPage";
import RemediesDetailPage from "./pages/remedies-detail-page/RemediesDetailPage";
import ProductDetailsPage from "./pages/product-details-page/ProductDetailsPage";
import AdvertsPage from "./pages/adverts-page/AdvertsPage";
import ReferenceStoresDetailPage from "./pages/reference-stores-detail-page/ReferenceStoresDetailPage";
import HumanResourcesFormPage from "./pages/human-resources-form-page/HumanResourcesFormPage";
import LearnDerinsisFormPage from "./pages/learn-derinsis-form-page/LearnDerinsisFormPage";
import ReferenceStoresPage from "./pages/reference-stores-page/ReferenceStoresPage";
import MicrosoftPartnerPage from "./pages/microsoft-partner-page/MicrosoftPartnerPage";
import CuriositiesListPage from "./pages/curiosities-list-page/CuriositiesListPage";
import CuriositiesDetailsPage from "./pages/curiosities-details-page/CuriositiesDetailsPage";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import {ApplicationRoutes} from "./consts/ApplicationConst";
import DerinbilgiInterestsPage from "./pages/derinbilgi-interests-page/DerinbilgiInterestsPage";
import VCardPage from "./pages/vcard-page/VCardPage";


function App() {
    return (
        <>
            <ContextProvider>
                <Router>
                    <ScrollToTop/>
                    <div className="app">
                        <Routes>
                            <Route path="/" element={<Layout/>}>
                                <Route index element={<HomePage/>}/>
                                <Route path={ApplicationRoutes.contact} element={<ContactPage/>}/>
                                <Route path={ApplicationRoutes.successStory} element={<SuccessStoriesListPage/>}/>
                                <Route path={`${ApplicationRoutes.successStoryDetail}/:slug`}
                                       element={<SuccessStoriesDetailsPage/>}/>
                                <Route path={ApplicationRoutes.news} element={<NewsPage/>}/>
                                <Route path={`${ApplicationRoutes.newsDetail}/:slug`} element={<NewsDetailPage/>}/>
                                <Route path={ApplicationRoutes.products} element={<ProductsPage/>}/>
                                <Route path={`${ApplicationRoutes.productDetail}/:id`}
                                       element={<ProductDetailsPage/>}/>
                                <Route path={ApplicationRoutes.remedies} element={<RemediesListPage/>}/>
                                <Route path={`${ApplicationRoutes.remediesDetail}/:slug`}
                                       element={<RemediesDetailPage/>}/>
                                <Route path={ApplicationRoutes.about} element={<DerinBilgiIntroducePage/>}/>
                                <Route path={ApplicationRoutes.ourInterests} element={<DerinbilgiInterestsPage/>}/>
                                <Route path={ApplicationRoutes.adverts} element={<AdvertsPage/>}/>
                                <Route path={ApplicationRoutes.certificationForm} element={<CertificationFormPage/>}/>
                                <Route path={ApplicationRoutes.learnDerinsisForm} element={<LearnDerinsisFormPage/>}/>
                                <Route path={ApplicationRoutes.humanResourceForm} element={<HumanResourcesFormPage/>}/>
                                <Route path={ApplicationRoutes.chainStores} element={<ReferenceStoresPage/>}/>
                                <Route path={ApplicationRoutes.stationery} element={<ReferenceStoresPage/>}/>
                                <Route path={ApplicationRoutes.wholesaleDistribution} element={<ReferenceStoresPage/>}/>
                                <Route path={ApplicationRoutes.ecommerceProjects} element={<ReferenceStoresPage/>}/>
                                <Route path={ApplicationRoutes.logisticsCompanies} element={<ReferenceStoresPage/>}/>
                                <Route path={ApplicationRoutes.toplaioProjects} element={<ReferenceStoresPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={`${ApplicationRoutes.askUs}/:name`}
                                       element={<ReferenceStoresDetailPage/>}/>
                                <Route path={ApplicationRoutes.microsoftPartner} element={<MicrosoftPartnerPage/>}/>
                                <Route path={ApplicationRoutes.curiosities} element={<CuriositiesListPage/>}/>
                                <Route path={`${ApplicationRoutes.curiositiesDetail}/:slug`}
                                       element={<CuriositiesDetailsPage/>}/>
                                <Route path={`${ApplicationRoutes.vcard}`}
                                       element={<VCardPage/>}/>
                            </Route>
                        </Routes>
                    </div>
                </Router>
            </ContextProvider>
        </>
    );
}

export default App;
