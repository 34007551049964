import React, {useEffect, useState} from 'react';
import './AdvertsPage.scss';
import {useAppContext} from "../../context/ContextProvider";
import {ReactComponent as ZoomIcon} from "../../images/zoomIcon.svg";
import classNames from "classnames";
import {Col, Image, Row, Spin} from "antd";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {AdvertType} from "../../helpers/types";


const AdvertsPage = () => {
    const classes = classNames("db-adverts-page");
    const isXSmallScreen = useIsSmallScreen(576);
    const {setTempValue} = useAppContext();
    const {getAdverts} = useApiHelper();
    const [dataAdverts, setDataAdverts] = useState<AdvertType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getAdverts();
            if (response?.Ilanlar) {
                setDataAdverts(response.Ilanlar);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        setTempValue("");
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'İlanlarımız'}
                description={'İlanlarımız'}
                keywords={'ilanlarimiz, adverts'}
                noIndex={false}
            />
            <div>
                <div className="header-title">İlanlarımız</div>
                <Row gutter={[50, 50]} align={"middle"}>
                    <Image.PreviewGroup>
                        {dataAdverts && dataAdverts
                            .filter(advert => advert.aktif)
                            .map((advert, index) => (
                                <Col xs={24} sm={12} lg={8} key={index} order={advert.sira}>
                                    <Image
                                        src={`https://derinbilgi-api.derinpos.com/${advert.fotograf}`}
                                        alt="ilan-resmi"
                                        preview={!isXSmallScreen && {mask: <ZoomIcon/>}}
                                        className="test"
                                    />
                                </Col>
                            ))}
                    </Image.PreviewGroup>
                </Row>
            </div>
        </div>
    );
};

export default AdvertsPage;
