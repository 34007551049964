import React, {useEffect} from 'react';
import '../Layout.scss'
import HumanResourcesForm from "../../components/forms/human-resources-form/HumanResourcesForm";
import {useAppContext} from "../../context/ContextProvider";
import CertificationFormImage from "../../images/CertificationFormImage.png";
import FormInfo from "../../components/forms/form-info/FormInfo";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const HumanResourcesFormPage = () => {
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div>
            <PageHelmet
                title={'İnsan Kaynakları Formu'}
                description={'İnsan Kaynakları Formu'}
                keywords={'insan kaynaklari formu, human resource form'}
                noIndex={false}
            />
            <FormInfo
                title={"Derinbilgi İK Başvuru Formu"}
                detail={"Yazılım, proje, eğitim ve pazarlama bölümlerinde görevlendirmek üzere; uygun fakültelerden mezun, tercihen perakende süreçlerinde ve ERP projelerinde deneyimli çalışma arkadaşları bekliyoruz."}
                image={CertificationFormImage}
            />
            <HumanResourcesForm/>
        </div>
    );
};

export default HumanResourcesFormPage;