import Toolbar from "../components/toolbar/Toolbar";
import "./Layout.scss";
import {Outlet} from "react-router";
import Footer from "../components/footer/Footer";
import HomeSliders from "../components/home/home-sliders/HomeSliders";
import ProductDetailsBanner from "../components/product-details/product-details-banner/ProductDetailsBanner";
import React, {useState} from "react";
import {useAppContext} from "../context/ContextProvider";
import CenteredLayout from "../components/centered-layout/CenteredLayout";
import sliderBack from "../images/SliderBack2.png"

const Layout = () => {
    const {tempValue} = useAppContext();
    const [homeImage, setHomeImage] = useState<string | undefined>(undefined);
    return (
        <div className="db-layout">
            <div
                style={{background: tempValue === "b" ? `url(https://derinbilgi-api.derinpos.com/${homeImage}) no-repeat center / cover` : tempValue === "a" ? "" : `url(${sliderBack}) no-repeat center / cover`}}>

                {
                    tempValue === "a" && (
                        <div className="bg-image-container">
                            <div className="bg-image-container-content"></div>
                        </div>
                    )
                }

                <Toolbar/>
                {tempValue === "a" ? (
                    <HomeSliders/>
                ) : tempValue === "b" ? (
                    <ProductDetailsBanner setHomeImage={setHomeImage}/>
                ) : null}
            </div>
            <CenteredLayout>
                <div className="content">
                    <Outlet/>
                </div>
            </CenteredLayout>
            <Footer/>
        </div>
    );
};

export default Layout;
