import React from 'react';
import "./PersonalInformationForm.scss"
import {Col, ConfigProvider, DatePicker, Form, Input, Row, Upload} from "antd";
import UploadSvg from "../../../../images/Upload.svg";
import classNames from "classnames";


interface PersonalInformationFormProps {
    formName: string,
    name: string
    education: string
    dateOfBirth: string
    cv?: string
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = (props) => {
    const {formName, name, education, dateOfBirth, cv} = props
    const classes = classNames("db-personal-information")

    return (
        <div className={classes}>
            <div className="form-title">
                Kişisel Bilgileriniz
            </div>
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={name}
                        rules={[{required: true, message: "Lütfen isminizi giriniz!"}]}
                    >
                        <Input placeholder="İsim"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={education}
                        rules={[{required: true, message: "Lütfen eğitim bilginizi giriniz!"}]}
                    >
                        <Input placeholder="Eğitim - Son mezun olduğunuz okulu yazınız"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <ConfigProvider
                    theme={{
                        components: {
                            DatePicker: {
                                activeBg: 'transparent',
                                activeBorderColor: '#828282',
                                cellHoverBg: '#000000',
                                cellHoverWithRangeBg: '#000000',
                                cellBgDisabled: '#000000',
                            }
                        },
                    }}
                >
                    <Col xs={24} md={12}>
                        <Form.Item
                            colon={false}
                            name={dateOfBirth}
                            rules={[{required: true, message: "Lütfen doğum tarihinizi giriniz!"}]}
                        >

                            <DatePicker
                                placeholder="Doğum tarihi"
                                className="date-picker"
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>
                </ConfigProvider>

                {
                    formName === "insan-kaynaklari-formu" && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={cv}
                                rules={[{required: false, message: "Lütfen Cv dosyanızı ekleyiniz!"}]}
                            >
                                <div className="cv-upload">
                                    <div>
                                        Cv Yükle
                                    </div>
                                    <Upload>
                                        <img src={UploadSvg} alt=""/>
                                    </Upload>
                                </div>
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
        ;
};

export default PersonalInformationForm;