import { FormInstance } from 'antd';
import { IResponse } from '../helpers/types';
import useApiHelper from './useApiHelper';

const useFormHelper = () => {
    const { parseErrorResponse } = useApiHelper();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function resetFormErrorsOnSuccess(form: FormInstance<any>) {
        // Formdaki tüm alanları dönerek errors'ları boş dizi ata
        Object.keys(form.getFieldsValue()).forEach((key) => {
            form.setFields([{ name: key, errors: [] }]);
        });
    }

    function writeFormErrorsOnFail(
        response: IResponse,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form: FormInstance<any>
    ) {
        const newErrorMessages = parseErrorResponse(response);
        if (newErrorMessages) {
            Object.keys(newErrorMessages).forEach((key) => {
                form.setFields([{ name: key, errors: newErrorMessages[key] }]);
            });
        }
    }

    return { resetFormErrorsOnSuccess, writeFormErrorsOnFail };
};

export default useFormHelper;
