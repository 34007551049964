import React from 'react';
import "./ContactInformationForm.scss"
import classNames from "classnames";
import {Col, Form, Input, Row} from "antd";


interface ContactInformationFormProps {
    email: string;
    phoneNumber: string;
    city: string;
    district: string;
    handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactInformationForm: React.FC<ContactInformationFormProps> = (props) => {
    const classes = classNames("db-contact-information-form")
    const {email, phoneNumber, city, district, handlePhoneChange} = props

    return (
        <div className={classes}>
            <div className="form-title">
                İletişim Bilgileriniz
            </div>
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={email}
                        rules={[{required: true, message: "Lütfen E-Mail adresinizi giriniz!"}]}
                    >
                        <Input placeholder="E-Mail"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={phoneNumber}
                        rules={[
                            {required: true, message: "Lütfen telefon numaranızı giriniz!"},
                            {
                                pattern: /^\(\d{3}\) \d{3} \d{2}-\d{2}$/,
                                message: "Lütfen geçerli bir telefon numarası giriniz! Örn: (555) 555 55-55"
                            }
                        ]}
                    >
                        <Input placeholder="Telefon No" onChange={handlePhoneChange}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={city}
                        rules={[{required: true, message: "Lütfen İl giriniz!"}]}
                    >
                        <Input placeholder="İl"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        colon={false}
                        name={district}
                        rules={[{required: true, message: "Lütfen İlçe giriniz!"}]}
                    >
                        <Input placeholder="İlçe"/>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default ContactInformationForm;