import React, {useEffect, useState} from 'react';
import './SuccessStoriesSliders.scss'
import classNames from "classnames";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/bundle';
import SuccessStoriesSlider from "./success-stories-slider/SuccessStoriesSlider";
import useApiHelper from "../../../hooks/useApiHelper";
import {ReviewListType} from "../../../helpers/types";


const SuccessStoriesSliders = () => {
    const classes = classNames('db-success-stories-sliders')
    const {getSuccessStories} = useApiHelper();
    const [dataSuccessStories, setDataSuccessStories] = useState<ReviewListType[] | undefined>(undefined);
    const getData = async () => {
        try {
            const response = await getSuccessStories();
            if (response?.BasariHikayeleri) {
                setDataSuccessStories(response.BasariHikayeleri);
            }
        } catch (error) {
            console.error('getData error:', error);
        }
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <div className={classes}>
            <div className="header-subtitle">
                Başarı Hikayeleri
            </div>
            <Swiper
                modules={[Navigation, Pagination]}
                navigation={true}
                pagination={{clickable: true}}
                spaceBetween={32}
                style={{"--swiper-navigation-size": "1.6vw"}}
            >
                {dataSuccessStories && dataSuccessStories
                    .filter(successStory => successStory.aktif)
                    .map((successStory, index) => (
                        <SwiperSlide key={index}>
                            <SuccessStoriesSlider successStory={successStory}/>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default SuccessStoriesSliders;