import React, {useEffect, useState} from 'react';
import {useAppContext} from "../../context/ContextProvider";
import ReviewListDetail from "../../components/review-list-detail/ReviewListDetail";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ReferenceStoreType} from "../../helpers/types";
import {useParams} from "react-router";
import {Spin} from "antd";


const ReferenceStoresDetailPage = () => {
    const {setTempValue} = useAppContext();
    const {getReferenceStoreDetail} = useApiHelper();
    const [dataReferenceStoreDetail, setDataReferenceStoreDetail] = useState<ReferenceStoreType | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);
    const {name} = useParams();

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getReferenceStoreDetail(name!);
            if (response?.BiziSorun) {
                setDataReferenceStoreDetail(response.BiziSorun);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }


    useEffect(() => {
        setTempValue("");
        getData()
    }, []);

    return (
        <div>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={dataReferenceStoreDetail?.baslik || ''}
                description={dataReferenceStoreDetail?.baslik}
                keywords={dataReferenceStoreDetail?.baslik}
                noIndex={false}
            />
            <ReviewListDetail
                baslik={dataReferenceStoreDetail?.baslik}
                alt_baslik={dataReferenceStoreDetail?.alt_baslik}
                metin={dataReferenceStoreDetail?.metin}
                fotograf={dataReferenceStoreDetail?.fotograf}
            />
        </div>
    );
};

export default ReferenceStoresDetailPage;