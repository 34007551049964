import React, {useEffect, useState} from 'react';
import './ReferenceStoresPage.scss'
import {useAppContext} from "../../context/ContextProvider";
import classNames from "classnames";
import {useLocation} from "react-router-dom";
import {Col, Row, Spin} from "antd";
import ReferenceStore from "../../components/reference-store/ReferenceStore";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ReferenceStoreType} from "../../helpers/types";


const ReferenceStoresPage = () => {
    const classes = classNames("db-reference-stores-page")
    const {pathname} = useLocation();
    const {setTempValue} = useAppContext();
    const {getReferenceStores} = useApiHelper();
    const [dataReferenceStores, setDataReferenceStores] = useState<ReferenceStoreType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);
    const getData = async () => {
        try {
            setLoad(true)
            const response = await getReferenceStores();
            if (response?.BiziSorunlar) {
                setDataReferenceStores(response.BiziSorunlar);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    let pageTitle;
    let type: string | number;
    switch (pathname) {
        case "/zincir-magazalar":
            pageTitle = "Zincir Mağazalar"
            type = 1
            break
        case "/kirtasiyeler":
            pageTitle = "Kırtasiyeler"
            type = 2
            break
        case "/toptan-dagitim":
            pageTitle = "Toptan/Dağıtım Şirketleri"
            type = 3
            break
        case "/eticaret-projeleri":
            pageTitle = "e-Ticaret Projeleri"
            type = 4
            break
        case "/lojistik-sirketleri":
            pageTitle = "Lojistik Şirketleri"
            type = 6
            break
        case "/topla-topla.io-pazaryeri-market-ici-hizli-toplama-uygulamasi":
            pageTitle = "Topla.io Projeleri"
            type = 5
            break
    }

    useEffect(() => {
        setTempValue("");
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={pageTitle}
                description={pageTitle}
                keywords={pageTitle}
                noIndex={false}
            />
            <div className="header-title">{pageTitle}</div>
            <Row
                gutter={[48, 64]}
            >
                {dataReferenceStores && dataReferenceStores
                    .filter(store => store.tip === type && store.aktif)
                    .map((stores, index) => (
                        <Col xs={24} md={12} xl={8} key={index}>
                            <ReferenceStore stores={stores} url={pathname}/>
                        </Col>
                    ))}
            </Row>
        </div>
    );
};

export default ReferenceStoresPage;