import { useState, useEffect } from "react";

export function useIsSmallScreen(MOBILE_WIDTH: number) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= MOBILE_WIDTH);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [MOBILE_WIDTH]);

  return isSmallScreen;
}
