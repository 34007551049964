import React from 'react';
import {Helmet} from 'react-helmet';

interface PageHelmetProps {
    title?: string;
    description?: string;
    keywords?: string;
    noIndex: boolean;
}

const PageHelmet: React.FC<PageHelmetProps> = ({
                                                   title,
                                                   description,
                                                   keywords,
                                                   noIndex,
                                               }) => {
    const suffixTitle: string = "Derin Bilgi Sistemleri";

    if (noIndex)
        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    {title} | {suffixTitle}
                </title>
                <meta name="robots" content="noindex"/>
                <meta
                    name="copyright"
                    content="Copyright © 2024 Derinbilgi Sistemleri E-Ticaret"
                />
            </Helmet>
        );

    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>
                {title} | {suffixTitle}
            </title>
            <meta name="description" content={description ?? ''}/>
            <meta name="keywords" content={keywords ?? ''}/>
            <meta name="robots" content="index, follow"/>
            <link rel="canonical" href={`${window.location.origin}/`}/>
            <meta
                name="copyright"
                content="Copyright © 2024 Derinbilgi Sistemleri E-Ticaret"
            />

            {/* İhtiyaç halinde başka meta etiketlerini ekleyebiliriz. */}
        </Helmet>
    );
};

export default PageHelmet;
