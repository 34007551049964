import React from 'react';
import './ReviewList.scss'
import {Col, Row} from "antd";
import classNames from "classnames";
import DetailButton from "../detail-button/DetailButton";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import {useNavigate} from "react-router";
import {ReviewListType} from "../../helpers/types";
import {truncateTextWithLine} from "../../helpers/Formatting";
import NoImage from "../../images/noImage.png";


interface ReviewListProps {
    title: string,
    url?: string,
    list: ReviewListType[]
}

const ReviewList: React.FC<ReviewListProps> = (props) => {
    const classes = classNames('db-review-list')
    const {title, url, list} = props
    const isSmallScreen = useIsSmallScreen(991)
    const navigate = useNavigate();

    return (
        <div className={classes}>
            <div className="review-list">
                <div className="header-title">{title}</div>
                {
                    list && list
                        .filter(item => item.aktif)
                        .map((item, index) => (
                            <Row className="review-list-item" gutter={[48, 32]} key={index}>
                                <Col xs={24} lg={12} order={!isSmallScreen ? item.text_sirasi : 1}>
                                    <div className="review-list-item-text">
                                        <div className="review-list-item-text-title">
                                            {item.baslik}
                                        </div>
                                        <div className="review-list-item-text-description" style={truncateTextWithLine(4)}>
                                            {item.alt_baslik}
                                        </div>
                                        <div
                                            className="review-list-item-text-detail-button"
                                            onClick={() => navigate(`${url}/${item.slug}`)}
                                        >
                                            <DetailButton/>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} lg={12} order={!isSmallScreen ? item.resim_sirasi : 0}>
                                    <div className="review-list-item-image">
                                        <img
                                            src={item.fotograf ? `https://derinbilgi-api.derinpos.com/${item.fotograf}` : NoImage}
                                            alt="review-list-item-img"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ))
                }
            </div>
        </div>
    );
};

export default ReviewList;