import React, {useEffect, useState} from "react";
import {useAppContext} from "../../context/ContextProvider";
import ReviewList from "../../components/review-list/ReviewList";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ReviewListType} from "../../helpers/types";
import {Spin} from "antd";


const SuccessStoriesListPage = () => {
    const {setTempValue} = useAppContext();
    const {getSuccessStories} = useApiHelper();
    const [dataSuccessStories, setDataSuccessStories] = useState<ReviewListType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getSuccessStories();
            if (response?.BasariHikayeleri) {
                setDataSuccessStories(response.BasariHikayeleri);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        setTempValue("");
        getData()
    }, []);

    return (
        <div>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Başarı Hikayelerimiz'}
                description={'Başarı Hikayelerimiz'}
                keywords={'basari hikayleri, success stories'}
                noIndex={false}
            />
            <ReviewList title="Başarı Hikayeleri" url={ApplicationRoutes.successStoryDetail} list={dataSuccessStories!}/>
        </div>
    );
};

export default SuccessStoriesListPage;
