import React, {useEffect, useState} from 'react';
import './News.scss';
import classNames from "classnames";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import useApiHelper from "../../hooks/useApiHelper";
import {NewsType} from "../../helpers/types";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SlideButtons from "../slide-buttons/SlideButtons";
import NewsItem from "./news-item/NewsItem";
import {Col, Pagination, Row} from "antd";
import DetailButton from "../detail-button/DetailButton";
import DateBadge from "../date-badge/DateBadge";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import {ReactComponent as PrevButton} from "../../images/prevButton.svg";
import {ReactComponent as NextButton} from "../../images/nextButton.svg";
import NoImage from '../../images/noImage.png'

const News = () => {
    const classes = classNames('db-news');
    const isSmallScreen = useIsSmallScreen(768);
    const {getNewsPaging} = useApiHelper();

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page') || '1';

    const [dataNews, setDataNews] = useState<NewsType[] | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(parseInt(pageParam)); // Sayfa numarasını izlemek için state eklendi
    const [totalItems, setTotalItems] = useState(0); // Toplam öğe sayısı için state eklendi

    const formatPageNumber = (page: number): string => {
        return page < 10 ? `0${page}` : `${page}`;
    };

    const itemRender = (
        page: number,
        type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
        originalElement: React.ReactNode
    ): React.ReactNode => {
        if (type === 'page') {
            return <a>{formatPageNumber(page)}</a>;
        }
        if (type === 'prev') {
            return <a className="news-pagination-prev-button"><PrevButton/></a>; // Özel ikon
        }
        if (type === 'next') {
            return <a className="news-pagination-next-button"><NextButton/></a>; // Özel ikon
        }
        return originalElement;
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        window.scrollTo({
            top: 770,
            behavior: 'smooth',
        });
    };

    const getData = async (page: number = 1) => {
        try {
            const response = await getNewsPaging(page);
            if (response?.results) {
                setDataNews(response.results);
                setTotalItems(response.count || 0);
            }
        } catch (error) {
            console.error('getData error:', error);
        }
    };

    useEffect(() => {
        getData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const urlPage = parseInt(pageParam);
        if (urlPage !== currentPage) {
            setCurrentPage(urlPage);
        }
    }, [location.search]);

    return (
        <div className={classes}>
            <div className="header-title">
                Haberler
            </div>
            <div className="news">
                <div className="news-top-slider">
                    <Swiper
                        modules={[Navigation]}
                        grabCursor={true}
                        pagination={{clickable: true}}
                        spaceBetween={32}
                        slidesPerView={1}
                    >
                        {dataNews && dataNews
                            .filter(news => news.aktif)
                            .filter(news => news.one_cikan_haberler)
                            .map((news, index) => (
                                <SwiperSlide key={index}>
                                    <NewsItem news={news}/>
                                </SwiperSlide>
                            ))}
                        {!isSmallScreen && <SlideButtons/>}
                    </Swiper>
                </div>
                <Row gutter={[16, 48]} className="news-pagination-list">
                    {
                        dataNews && dataNews
                            .filter(news => news.aktif)
                            .map((news, index) => (
                                <Row gutter={[32, 32]} className="news-pagination-list-item" key={index}>
                                    <Col xs={24} md={8} className="news-pagination-list-item-image">
                                        <img
                                            src={news.fotograf ? `https://derinbilgi-api.derinpos.com/${news.fotograf}` : NoImage}
                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                const target = e.target as HTMLImageElement;
                                                target.src = NoImage!;
                                            }}
                                            alt="haber-img"
                                        />
                                    </Col>
                                    <Col xs={24} sm={16} md={10} lg={12} className="news-pagination-list-item-text">
                                        <DateBadge date={news.tarih}/>
                                        <div className="news-pagination-list-item-text-title">
                                            {news.baslik}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={4} className="news-pagination-list-item-detail-button">
                                        <Link to={`${ApplicationRoutes.newsDetail}/${news.slug}`}>
                                            <DetailButton/>
                                        </Link>
                                    </Col>
                                </Row>
                            ))
                    }
                </Row>
                <div className="news-pagination">
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        showSizeChanger={false}
                        pageSize={10}
                        onChange={handlePageChange}
                        itemRender={itemRender}
                        responsive
                        showLessItems
                    />
                </div>
            </div>
        </div>
    );
};

export default News;
