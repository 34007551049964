import React from 'react';
import './DateBadge.scss'
import classNames from "classnames";


interface DateBadgeProps {
    date?: string
}

const DateBadge: React.FC<DateBadgeProps> = (props) => {
    const classes = classNames('db-date-badge')
    const {date} = props

    return (
        <div className={classes}>
            {date}
        </div>
    );
};

export default DateBadge;