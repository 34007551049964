import React from 'react';
import './RemedySlider.scss'
import classNames from "classnames";
import {Link} from "react-router-dom";
import DetailButton from "../../../detail-button/DetailButton";
import {ApplicationRoutes} from "../../../../consts/ApplicationConst";
import {RemediesDetail} from "../../../../helpers/types";

interface RemedySliderProps {
    remedy: RemediesDetail
}

const RemedySlider: React.FC<RemedySliderProps> = (props) => {
    const {remedy} = props
    const classes = classNames('db-remedy-slider')

    return (
        <div className={classes}>
            <div className="remedy-slider">
                <div className="remedy-slider-background"
                     style={{backgroundImage: `url(https://derinbilgi-api.derinpos.com/${remedy.fotograf})`}}
                />
                <div className="remedy-slider-content">
                    <div className="header-subtitle remedy-slider-content-title">
                        {remedy.baslik}
                    </div>
                    <div className="remedy-slider-content-bottom">
                        <div className="remedy-slider-content-bottom-description">
                            {remedy.alt_baslik}
                        </div>
                        <div className="remedy-slider-content-bottom-detail-button">
                            <Link to={`${ApplicationRoutes.remediesDetail}/${remedy.slug}`}>
                                <DetailButton/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemedySlider;
