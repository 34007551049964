import React from 'react';
import './SlideButtons.scss'
import classNames from "classnames";
import {useSwiper} from 'swiper/react';
import prevButton from "../../images/prevButton.svg";
import nextButton from "../../images/nextButton.svg";
import slidesLine from "../../images/slidesLine.svg";

const SlideButtons = () => {
    const classes = classNames("db-slide-buttons")

    const swiper = useSwiper();
    const goToNextSlide = () => {
        swiper.slideNext();
    };

    const goToPrevSlide = () => {
        swiper.slidePrev();
    };


    return (
        <div className={classes}>
            <div onClick={goToPrevSlide} className="prev-button">
                <img src={prevButton} alt=""/>
            </div>
            <div className="line">
                <img src={slidesLine} alt="line"/>
            </div>
            <div onClick={goToNextSlide} className="next-button">
                <img src={nextButton} alt=""/>
            </div>
        </div>
    );
};

export default SlideButtons;