import React, {useEffect} from "react";
import NewsSliders from "../../components/home/news-sliders/NewsSliders";
import References from "../../components/home/references/References";
import HomeDescription from "../../components/home/home-descripton/HomeDescription";
import SuccessStory from "../../components/home/success-stories-sliders/SuccessStoriesSliders";
import "./HomePage.scss";
import classNames from "classnames";
import {useAppContext} from "../../context/ContextProvider";
import RemediesSliders from "../../components/home/remedies-sliders/RemediesSliders";
import PageHelmet from "../../components/page-helmet/PageHelmet";

const HomePage = () => {
    const classes = classNames("db-content-pages");
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("a");
    }, []);

    return (
        <div className={classes}>
            <PageHelmet
                title={'Anasayfa'}
                description={'Anasayfa'}
                keywords={'anasayfa, homepage'}
                noIndex={false}
            />
            <div className="home-page-flex">
                <div>
                    <RemediesSliders/>
                </div>
                <div>
                    <HomeDescription/>
                </div>
                <div>
                    <SuccessStory/>
                </div>
                <div>
                    <NewsSliders/>
                </div>
                <div>
                    <References/>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
