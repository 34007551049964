import React, {useEffect, useState} from 'react';
import './ProductDetailsPage.scss'
import classNames from "classnames";
import {useAppContext} from "../../context/ContextProvider";
import ProductDetailsInformation
    from "../../components/product-details/product-details-information/ProductDetailsInformation";
import ProductDetailsIntroduce
    from "../../components/product-details/product-details-introduce/ProductDetailsIntroduce";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import {useParams} from "react-router";
import useApiHelper from "../../hooks/useApiHelper";
import {ProductDetailType, ProductType} from "../../helpers/types";
import ProductDetailsRemedies from "../../components/product-details/product-details-remedies/ProductDetailsRemedies";
import {Spin} from "antd";
import NoImage from "../../images/noImage.png";


const ProductDetailsPage = () => {
    const classes = classNames("db-product-details-page");
    const {setTempValue} = useAppContext();
    const {id} = useParams()
    const {getProductDetail} = useApiHelper();
    const [dataProductDetail, setDataProductDetail] = useState<ProductDetailType | undefined>(undefined);
    const [dataProduct, setDataProduct] = useState<ProductType | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getProductDetail(id);
            if (response?.urundetay) {
                setDataProductDetail(response.urundetay);
                setDataProduct(response.urunbilgileri)
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        setTempValue("b");
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Ürünler'}
                description={'Ürünler'}
                keywords={'urunler, products'}
                noIndex={false}
            />
            <div className="product-details-page">
                <div className="product-details-page-product-image">
                    <img
                        src={dataProductDetail?.urun_resim ? `https://derinbilgi-api.derinpos.com/${dataProductDetail?.urun_resim}` : NoImage}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            const target = e.target as HTMLImageElement;
                            target.src = NoImage!;
                        }}
                        alt="product-img"/>
                </div>
                <div className="product-details-page-content">
                    <ProductDetailsInformation
                        urun_detay_bilgiler={dataProductDetail?.urun_detay_bilgiler ? dataProductDetail?.urun_detay_bilgiler : []}
                    />
                    <ProductDetailsIntroduce
                        urun_kisa_adi={dataProduct?.urun_kisa_adi ? dataProduct?.urun_kisa_adi : ''}
                        urun_rengi={dataProduct?.urun_rengi ? dataProduct?.urun_rengi : ''}
                        tanitim_baslik={dataProductDetail?.tanitim_baslik}
                        tanitim_aciklama={dataProductDetail?.tanitim_aciklama ? dataProductDetail?.tanitim_aciklama : ''}
                        urun_opsiyon_secenekleri={dataProductDetail?.urun_opsiyon_secenekleri ? dataProductDetail?.urun_opsiyon_secenekleri : []}
                    />
                </div>
            </div>
            <div
                style={{background: `url(https://derinbilgi-api.derinpos.com/${dataProductDetail?.urun_cozumler_arkaplan_resim}) no-repeat center / cover`}}
                className="product-details-page-remedies">
                <div className="header-subtitle product-details-page-remedies-title">
                    Kullanılan Çözümler
                </div>
            </div>
            <ProductDetailsRemedies
                urun_rengi={dataProduct?.urun_rengi ? dataProduct?.urun_rengi : ''}
                detaylar={dataProductDetail?.cozumler.detaylar}
            />
        </div>
    );
};

export default ProductDetailsPage;