import React, {useEffect, useState} from 'react';
import './OptionArrow.scss'
import classNames from "classnames";
import {Button} from "antd";
import {ReactComponent as DownArrowButton} from "../../images/downArrowButton.svg"
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import DynamicArrowButton from "./DynamicArrowButton";
import DynamicUpArrowButton from "./DynamicUpArrowButton";


interface OptionArrowProps {
    label: string;
    index: number;
    setSelectedOptionIndex: React.Dispatch<React.SetStateAction<number>>
    selectedIndex: number;
    productColor?: string;
}

const OptionArrow: React.FC<OptionArrowProps> = (props) => {
    const {label, index, setSelectedOptionIndex, selectedIndex, productColor} = props
    const classes = classNames('db-option-arrow')
    const isMediumScreen = useIsSmallScreen(1024)
    const [isSelected, setIsSelected] = useState(false);


    useEffect(() => {
        setIsSelected(index === selectedIndex);
    }, [index, selectedIndex]);

    return (
        <div className={classes}>
            <div
                className={`option ${isSelected ? 'selected-option' : ''}`}
                style={{
                    color: isSelected ? productColor : '',
                    borderBottom: isSelected ? `1px solid ${productColor}` : ''
                }}
                key={index}
                onClick={() => setSelectedOptionIndex(index)} // mobil görünümdeki hover effect in bozulmaması için
                onMouseEnter={() => setSelectedOptionIndex(index)}
            >
                <div>
                    {label}
                </div>
                <Button type="text" className="option-button">
                    {
                        !isMediumScreen
                            ? (<DynamicArrowButton fillColor={productColor}/>)
                            : (isSelected
                                ? <DynamicUpArrowButton fillColor={productColor}/>
                                : <DownArrowButton/>)
                    }
                </Button>
            </div>
        </div>
    );
};

export default OptionArrow;