import {useAppContext} from "../../context/ContextProvider";
import React, {useEffect, useState} from "react";
import './NewsDetailPage.scss'
import PageHelmet from "../../components/page-helmet/PageHelmet";
import NewsDetailPagination from "../../components/news-detail-pagination/NewsDetailPagination";
import ReviewListDetail from "../../components/review-list-detail/ReviewListDetail";
import {Col, Row} from "antd";
import classNames from "classnames";
import useApiHelper from "../../hooks/useApiHelper";
import {useParams} from "react-router";
import {NewsDetailType} from "../../helpers/types";

const NewsDetailPage = () => {
    const classes = classNames('db-news-detail-page')
    const {setTempValue} = useAppContext();
    const {getNewsDetail} = useApiHelper();
    const {slug} = useParams()
    useEffect(() => {
        setTempValue('');
    }, []);
    const [dataNewsDetail, setDataNewsDetail] = useState<NewsDetailType | undefined>(undefined);
    const [dataPrevNewsDetail, setDataPrevNewsDetail] = useState<NewsDetailType | undefined>(undefined);
    const [dataNextNewsDetail, setDataNextNewsDetail] = useState<NewsDetailType | undefined>(undefined);
    const getData = async () => {
        try {
            const response = await getNewsDetail(slug!);
            if (response?.Haber) {
                setDataNewsDetail(response.Haber);
                setDataNextNewsDetail(response.next);
                setDataPrevNewsDetail(response.previous);
            }
        } catch (error) {
            console.error('getData error:', error);
        }
    }
    useEffect(() => {
        getData();
    }, [slug]);
    return (
        <div className={classes}>
            <PageHelmet
                title={'Haberler'}
                description={'Haberler'}
                keywords={'haberler, news-page'}
                noIndex={false}
            />
            <ReviewListDetail
                baslik={dataNewsDetail?.baslik}
                alt_baslik={dataNewsDetail?.alt_baslik}
                metin={dataNewsDetail?.metin}
                fotograf={dataNewsDetail?.fotograf}
                tarih={dataNewsDetail?.tarih}
            />
            <div>
                <Row gutter={[96, 48]}>
                    <Col xs={24} sm={12}>
                        <NewsDetailPagination
                            slug={dataPrevNewsDetail?.slug}
                            rowTitle="Önceki Haber"
                            textOrder={1}
                            imageOrder={0}
                            resim={dataPrevNewsDetail?.fotograf}
                            tarih={dataPrevNewsDetail?.tarih}
                            baslik={dataPrevNewsDetail?.baslik}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <NewsDetailPagination
                            slug={dataNextNewsDetail?.slug}
                            rowTitle="Sonraki Haber"
                            textOrder={0}
                            imageOrder={1}
                            resim={dataNextNewsDetail?.fotograf}
                            tarih={dataNextNewsDetail?.tarih}
                            baslik={dataNextNewsDetail?.baslik}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default NewsDetailPage;
