import {useEffect, useState} from 'react';
import './ScrollToTop.scss';
import {useLocation} from 'react-router-dom';
import {ReactComponent as UpIcon} from '../../images/upArrowButton.svg'

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const {pathname} = useLocation();

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible(scrollTop > 500);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.scroll(0, 0);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname]);

    return (
        isVisible ? (
            <div onClick={scrollToTop} className="scroll-top-btn">
                <UpIcon/>
            </div>
        ) : null
    );
};

export default ScrollToTop;
