import React from 'react';
import './InfinityScroll.scss'
import Grosseri from '../../images/Grosseri.svg'
import HakmarExpress from '../../images/HakmarExpress.svg'
import ShowMarket from '../../images/ShowMarket.svg'
import BarisGross from '../../images/BarisGross.svg'
import Vatan from '../../images/Vatan.svg'
import Praktiker from '../../images/Praktiker.svg'
import PetrolOfisi from '../../images/PetrolOfisi.svg'
import classNames from "classnames";


const InfinityScroll = () => {
    const classes = classNames('db-infinity-scroll')

    const Brands: string[] =
        [
            `${Grosseri}`,
            `${ShowMarket}`,
            `${PetrolOfisi}`,
            `${HakmarExpress}`,
            `${Praktiker}`,
            `${BarisGross}`,
            `${Vatan}`,
        ];
    const Duration: number = 20000;
    const Cols: number = 2;
    const BrandsPerCol: number = 7;

    const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;

    const InfiniteLoopSlider = ({children, duration, reverse = false}: {
        children: React.ReactNode;
        duration: number;
        reverse?: boolean
    }) => {
        return (
            <div className='loop-slider' style={{
                '--duration': `${duration}ms`,
                '--direction': reverse ? 'reverse' : 'normal'
            } as React.CSSProperties}>
                <div className='inner'>
                    {children}
                    {children}
                </div>
            </div>
        );
    };

    const Brand = ({brandImage}: { brandImage: string; }) => (
        <div className='brand'>
            <img className={"brand-image"} src={brandImage} alt=""/>
        </div>
    );

    return (
        <div className={classes}>
            <div className='brand-list'>
                {[...Array(Cols)].map((_, i) => (
                    <InfiniteLoopSlider
                        key={i}
                        duration={random(Duration - 5000, Duration + 5000)}
                        reverse={i % 2 === 0}
                    >
                        {
                            Brands.slice(0, BrandsPerCol).map((tag, index) => (
                                <Brand brandImage={tag} key={index}/>
                            ))
                        }
                    </InfiniteLoopSlider>
                ))}
                <div className='fade'/>
            </div>
        </div>
    );
};

export default InfinityScroll;
