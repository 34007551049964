import React, {useEffect, useState} from 'react';
import './ProductDetailsIntroduce.scss'
import classNames from "classnames";
import OptionArrow from "../../option-arrow/OptionArrow";
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
import {ProductDetailOptions} from "../../../helpers/types";
import NoImage from "../../../images/noImage.png";

interface ProductDetailsIntroduceProps {
    urun_kisa_adi: string;
    urun_rengi: string;
    tanitim_baslik?: string;
    tanitim_aciklama: string;
    urun_opsiyon_secenekleri: ProductDetailOptions[];
}

const ProductDetailsIntroduce: React.FC<ProductDetailsIntroduceProps> = (props) => {
    const classes = classNames('db-product-details-introduce');
    const {urun_kisa_adi, urun_rengi, tanitim_baslik, tanitim_aciklama, urun_opsiyon_secenekleri} = props;
    const isMediumScreen = useIsSmallScreen(1024);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        setSelectedIndex(selectedOptionIndex);
    }, [selectedOptionIndex]);

    const selectedOptionContent = urun_opsiyon_secenekleri[selectedOptionIndex]?.resim_icerigi[0];

    return (
        <div className={classes}>
            <div className="product-details-introduce">
                {
                    !isMediumScreen
                        ? (
                            <>
                                <div className="product-details-introduce-text">
                                    <div className="product-details-introduce-text-title">
                                        {tanitim_baslik}
                                    </div>
                                    <div className="product-details-introduce-text-description">
                                        {tanitim_aciklama}
                                    </div>
                                    <div className="product-details-introduce-text-options">
                                        {urun_opsiyon_secenekleri.map((option, index) => (
                                            <OptionArrow
                                                key={index}
                                                label={option.label}
                                                index={index}
                                                setSelectedOptionIndex={setSelectedOptionIndex}
                                                selectedIndex={selectedIndex}
                                                productColor={urun_rengi}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="product-details-introduce-image">
                                    {selectedOptionContent && (
                                        <>
                                            <img
                                                src={selectedOptionContent.resim ? `https://derinbilgi-api.derinpos.com/${selectedOptionContent.resim}` : NoImage}
                                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.src = NoImage!;
                                                }}
                                                alt="introduce-img"
                                            />
                                            <div className="product-details-introduce-image-text">
                                                {selectedOptionContent.text}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )
                        : (
                            <div className="product-details-introduce-text">
                                <div className="product-details-introduce-text-title">
                                    {tanitim_baslik}
                                </div>
                                <div className="product-details-introduce-text-description">
                                    {tanitim_aciklama}
                                </div>
                                <div className="product-details-introduce-text-options">
                                    {urun_opsiyon_secenekleri.map((option, index) => (
                                        <>
                                            <OptionArrow
                                                key={index}
                                                label={option.label}
                                                index={index}
                                                setSelectedOptionIndex={setSelectedOptionIndex}
                                                selectedIndex={selectedIndex}
                                                productColor={urun_rengi}
                                            />
                                            {selectedOptionIndex === index && (
                                                <div className="product-details-introduce-image">
                                                    {option.resim_icerigi[0] && (
                                                        <>
                                                            <img
                                                                src={option.resim_icerigi[0].resim ? `https://derinbilgi-api.derinpos.com/${option.resim_icerigi[0].resim}` : NoImage}
                                                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    target.src = NoImage!;
                                                                }}
                                                                alt=""
                                                            />
                                                            <div className="product-details-introduce-image-text">
                                                                {option.resim_icerigi[0].text}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default ProductDetailsIntroduce;
