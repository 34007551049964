import React from 'react';
import './FormInfo.scss'
import classNames from "classnames";
import info from "../../../images/info.svg";


interface FormInfoProps {
    title: string,
    detail: string,
    image: string
}

const FormInfo: React.FC<FormInfoProps> = (props) => {
    const classes = classNames('db-form-info')
    const {title, detail, image} = props

    return (
        <div className={classes}>
            <div className="header-title">
                {title}
            </div>
            <div className="info-card">
                <div className="info-card-text">
                    <img src={info} alt="info-icon"/>
                    <div className="info-card-detail">
                        {detail}
                    </div>
                </div>
                <div className="info-card-image">
                    <img src={image} alt="info-img"/>
                </div>
            </div>
        </div>
    );
};

export default FormInfo;