import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, Drawer, Menu} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {Link, useLocation} from "react-router-dom";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import SocialMedias from "../social-medias/SocialMedias";
import {menuTheme, mobilMenuTheme} from "../../themeConfig/menuTheme";
import {MenuItems} from "../../data/MenuItems";
import DerinBilgiLogo from "../../images/Derin-Bilgi-logo.svg";
import "./Toolbar.scss";
import classNames from "classnames";

const Toolbar = () => {
    const classes = classNames("db-toolbar");
    const isSmallScreen = useIsSmallScreen(1024);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const {pathname} = useLocation();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname]);

    const handleDrawerOpen = () => {
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
    };

    const handleMenuOpenChange = (keys: string[]) => {
        if (keys.length === 0) {
            setOpenKeys([]);
        } else {
            setOpenKeys([keys.pop()!]);
        }
    };

    const handleMenuClick = (url?: string, isSubMenuItem?: boolean) => {
        if (!isSubMenuItem) {
            setOpenKeys([]);
        }
        setDrawerVisible(false);
    };

    useEffect(() => {
        const currentPath = pathname.split('/')[1];
        const fullPath = `/${currentPath}`;
        let activeMenuItemKey: string | null = null;
        MenuItems.forEach((item) => {
            if (item.url === fullPath) {
                activeMenuItemKey = item.key.toString();
            } else if (item.children) {
                const childItem = item.children.find((child) => child.url === fullPath);
                if (childItem) {
                    activeMenuItemKey = childItem.key.toString();
                }
            }
        });

        if (activeMenuItemKey) {
            setSelectedKeys([activeMenuItemKey]);
        } else {
            setSelectedKeys([]);
        }
    }, [pathname]);

    return (
        <div className={classes}>
            {!isSmallScreen ? (
                <div className="toolbar">
                    <div className="toolbar-content">
                        <div className="toolbar-content-logo">
                            <Link to="/">
                                <img src={DerinBilgiLogo} alt="DerinBilgiSistemleriLogo"/>
                            </Link>
                        </div>
                        <ConfigProvider theme={menuTheme}>
                            <div className="toolbar-content-menu">
                                <Menu
                                    mode="horizontal"
                                    selectedKeys={selectedKeys}
                                    className={"toolbar-theme"}
                                    forceSubMenuRender={true}
                                    disabledOverflow={true}
                                >
                                    {MenuItems.map((item) => (
                                        item.children ? (
                                            <Menu.SubMenu
                                                key={item.key}
                                                title={item.label}
                                                className={` ${selectedKeys.includes(item.key.toString()) || item.children.some((child) => selectedKeys.includes(child.key.toString())) ? 'active' : ''}`}
                                            >
                                                {item.children.map((subItem) => (
                                                    <Menu.Item
                                                        key={subItem.key}
                                                        onClick={() => handleMenuClick(subItem.url, true)}
                                                    >
                                                        <Link to={subItem.url!}>
                                                            {subItem.label}
                                                        </Link>
                                                    </Menu.Item>
                                                ))}
                                            </Menu.SubMenu>
                                        ) : (
                                            <Menu.Item
                                                key={item.key}
                                                className={` ${selectedKeys.includes(item.key.toString()) ? 'active' : ''}`}
                                                onClick={() => handleMenuClick(item.url, false)}
                                            >
                                                <Link to={item.url ?? ''}>
                                                    {item.label}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    ))}
                                </Menu>
                                <div className="toolbar-content-menu-social-medias">
                                    <SocialMedias/>
                                </div>
                            </div>
                        </ConfigProvider>
                    </div>
                </div>
            ) : (
                <ConfigProvider theme={mobilMenuTheme}>
                    <div className="mobile-toolbar">
                        <div className="mobile-toolbar-logo">
                            <Link to="/">
                                <img
                                    src={DerinBilgiLogo}
                                    className="toolbar-logo"
                                    alt="DerinBilgiSistemleriLogo"
                                />
                            </Link>
                        </div>
                        <Button
                            type="default"
                            icon={<MenuOutlined/>}
                            onClick={handleDrawerOpen}
                            className="mobile-toolbar-toggle-button"
                        />
                        <Drawer
                            placement="left"
                            closable={true}
                            onClose={handleDrawerClose}
                            open={drawerVisible}
                        >
                            <Menu
                                mode="inline"
                                selectedKeys={selectedKeys}
                                openKeys={openKeys}
                                onOpenChange={handleMenuOpenChange}
                                forceSubMenuRender={true}
                                disabledOverflow={true}
                            >
                                {MenuItems.map((item) =>
                                    item.children ? (
                                        <Menu.SubMenu
                                            key={item.key}
                                            title={item.label}
                                            className="menu-item has-submenu"
                                        >
                                            {item.children.map((subItem) => (
                                                <Menu.Item
                                                    key={subItem.key}
                                                    onClick={() => handleMenuClick(subItem.url, true)}
                                                >
                                                    <Link className="menu-link" to={subItem.url!}>
                                                        {subItem.label}
                                                    </Link>
                                                </Menu.Item>
                                            ))}
                                        </Menu.SubMenu>
                                    ) : (
                                        <Menu.Item
                                            key={item.key}
                                            onClick={() => handleMenuClick(item.url, false)}
                                        >
                                            <Link className="menu-link" to={item.url ?? ''}>
                                                {item.label}
                                            </Link>
                                        </Menu.Item>
                                    )
                                )}
                            </Menu>
                            <SocialMedias/>
                        </Drawer>
                    </div>
                </ConfigProvider>
            )}
        </div>
    );
};

export default Toolbar;
