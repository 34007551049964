import React from 'react';
import './ProductDetailsInformation.scss'
import classNames from "classnames";
import {Col, Row} from "antd";
import {ProductDetailInfo} from "../../../helpers/types";
import NoImage from "../../../images/noImage.png";


interface ProductDetailsInformationProps {
    urun_detay_bilgiler: ProductDetailInfo[];
}

const ProductDetailsInformation: React.FC<ProductDetailsInformationProps> = (props) => {
    const classes = classNames('db-product-details-sliders')
    const {urun_detay_bilgiler} = props

    return (
        <div className={classes}>
            <Row gutter={32}>
                {
                    urun_detay_bilgiler.map((item, index) => (
                        <Col
                            xs={24} sm={12} lg={8}
                            className="product-details-sliders-card"
                        >
                            <div className="product-details-sliders-card-text">
                                <p>
                                    {item.baslik}
                                </p>
                            </div>
                            <div className="product-details-sliders-card-image">
                                <img
                                    src={item.resim ? `https://derinbilgi-api.derinpos.com${item.resim}` : NoImage}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        const target = e.target as HTMLImageElement;
                                        target.src = NoImage!;
                                    }}
                                    alt="detail-img"
                                />
                            </div>
                            <div className="product-details-sliders-card-detail">
                                <p>
                                    {item.aciklama}
                                </p>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default ProductDetailsInformation;