import {ApplicationRoutes} from "../consts/ApplicationConst";
import {FooterItemType} from "../components/footer/type";

export const FooterItems: FooterItemType[] = [
    {
        key: 1,
        label: "Hakkımızda",
        children: [
            {
                key: 11,
                label: "Derin Bilgi",
                url: ApplicationRoutes.about,
            },
            {
                key: 12,
                label: "Haberler",
                url: ApplicationRoutes.news,
            },
            {
                key: 13,
                label: "İlgi Alanlarımız",
                url: ApplicationRoutes.about,
            },
            {
                key: 14,
                label: "Microsoft İş Ortaklığı",
                url: ApplicationRoutes.microsoftPartner,
            },
            {
                key: 15,
                label: "İletişim",
                url: ApplicationRoutes.contact,
            },
        ],
    },
    {
        key: 2,
        label: "Ürünler",
        children: [
            {
                key: 21,
                label: "Topla.io",
                url: ApplicationRoutes.productDetail + "/toplaio",
            },
            {
                key: 22,
                label: "Shelfmatic",
                url: ApplicationRoutes.productDetail + "/shelfmatic",
            },
            {
                key: 23,
                label: "DerinSis",
                url: ApplicationRoutes.productDetail + "/derinsis",
            },
            {
                key: 24,
                label: "E-Grosseri",
                url: ApplicationRoutes.productDetail + "/e-grosseri",
            },
            {
                key: 25,
                label: "DerinKasa",
                url: ApplicationRoutes.productDetail + "/derin-kasa",
            },
        ],
    },
    {
        key: 3,
        label: "Bizi Sorun",
        children: [
            {
                key: 31,
                label: "Zincir Mağazalar",
                url: ApplicationRoutes.chainStores,
            },
            {
                key: 32,
                label: "Lojistik Şirketleri",
                url: ApplicationRoutes.logisticsCompanies,
            },
            {
                key: 33,
                label: "Kırtasiyeler",
                url: ApplicationRoutes.stationery,
            },
            {
                key: 34,
                label: "Toptan/Dağıtım Şirketleri",
                url: ApplicationRoutes.wholesaleDistribution,
            },
            {
                key: 35,
                label: "Topla.io Projeleri",
                url: ApplicationRoutes.toplaioProjects
            },
            {
                key: 35,
                label: "e-Ticaret Projeleri",
                url: ApplicationRoutes.ecommerceProjects,
            },
        ],
    },
];