import React from 'react';
import './NewsDetailPagination.scss'
import classNames from "classnames";
import {Col, Row} from "antd";
import {useNavigate} from "react-router";
import {ApplicationRoutes} from "../../consts/ApplicationConst";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import DateBadge from "../date-badge/DateBadge";
import NoImage from '../../images/noImage.png'


interface NewsDetailPaginationProps {
    slug: string | undefined,
    rowTitle: string,
    textOrder: number,
    imageOrder: number,
    resim: string | undefined,
    tarih: string | undefined,
    baslik: string | undefined,
}

const NewsDetailPagination: React.FC<NewsDetailPaginationProps> = (props) => {
    const classes = classNames('db-news-detail-pagination')
    const {slug, rowTitle, textOrder, imageOrder, resim, tarih, baslik} = props
    const navigate = useNavigate()
    const isMobile = useIsSmallScreen(576)
    const isTablet = useIsSmallScreen(991)

    let effectiveTextOrder = textOrder;
    let justifyContent;
    if (isTablet && !isMobile) {
        effectiveTextOrder = 1;
        justifyContent = 'center'
    }

    return (
        <div className={classes}>
            {
                slug && (
                    <div
                        className="news-detail-pagination"
                        onClick={() => navigate(`${ApplicationRoutes.newsDetail}/${slug}`)}
                    >
                        <div
                            className={`news-detail-pagination-title ${textOrder === 0 && 'news-detail-pagination-title-next'}`}>
                            {rowTitle}
                        </div>
                        <Row gutter={[24, 24]} className="news-detail-pagination-content">
                            <Col xs={12} sm={24} lg={10} order={imageOrder}
                                 style={{justifyContent: justifyContent}}
                                 className={`news-detail-pagination-content-image ${imageOrder === 1 && 'news-page-detail-pagination-content-image-next'}`}>
                                <img
                                    src={resim ? `https://derinbilgi-api.derinpos.com/${resim}` : NoImage}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        const target = e.target as HTMLImageElement;
                                        target.src = NoImage!;
                                    }}
                                    alt="haber-img"
                                />
                            </Col>
                            <Col xs={12} sm={24} lg={14} order={effectiveTextOrder}
                                 className="news-detail-pagination-content-text">
                                <DateBadge date={tarih}/>
                                <div className="news-detail-pagination-content-text-title">{baslik}</div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </div>
    );
};

export default NewsDetailPagination;