import React, {useEffect, useState} from 'react';
import {useAppContext} from "../../context/ContextProvider";
import ReviewListDetail from "../../components/review-list-detail/ReviewListDetail";
import PageHelmet from "../../components/page-helmet/PageHelmet";
import useApiHelper from "../../hooks/useApiHelper";
import {ReviewListType} from "../../helpers/types";
import {useParams} from "react-router";
import {Spin} from "antd";

const CuriositiesDetailsPage = () => {
    const {setTempValue} = useAppContext();
    const {getCuriositiesDetail} = useApiHelper();
    const [dataCuriositiesDetail, setDataCuriositiesDetail] = useState<ReviewListType | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);
    const {slug} = useParams();

    const getData = async () => {
        try {
            setLoad(true)
            const response = await getCuriositiesDetail(slug);
            if (response?.MerakEttikleriniz) {
                setDataCuriositiesDetail(response.MerakEttikleriniz);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        setTempValue("");
        getData()
    }, []);

    return (
        <div>
            <Spin spinning={load} fullscreen={true}/>
            <PageHelmet
                title={'Merak Ettikleriniz'}
                description={'Merak Ettikleriniz'}
                keywords={'merak ettikleriniz, curiosities'}
                noIndex={false}
            />
            <ReviewListDetail
                baslik={dataCuriositiesDetail?.baslik}
                alt_baslik={dataCuriositiesDetail?.alt_baslik}
                metin={dataCuriositiesDetail?.metin}
                fotograf={dataCuriositiesDetail?.fotograf}
            />
        </div>
    );
};

export default CuriositiesDetailsPage;