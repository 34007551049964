export const mobilMenuTheme = {
    components: {
        Menu: {
            itemBg: "transparent",
            itemColor: "rgba(202, 202, 202, 1)",
            itemSelectedBg: "#4F4F4F",
            borderRadius: 0,
            itemActiveBg: "#4F4F4F",
            itemSelectedColor: "#ffffff",
            itemHoverColor: "#ffffff",
            fontSize: 18,
            darkPopupBg: "#fff"

        },
        Drawer: {
            colorBgElevated: "rgba(0,0,0,1)",
            colorIcon: "#ffffff",
            colorIconHover: "#ffffff",
        },
        Button: {
            defaultBg: "transparent",
            defaultBorderColor: "transparent",
            defaultColor: "#ffffff",
            defaultHoverBg: "transparent",
            defaultHoverBorderColor: "transparent",
            defaultHoverColor: "#ffffff",
            defaultActiveBg: "transparent",
            defaultActiveBorderColor: "transparent",
            defaultActiveColor: "#ffffff",
            onlyIconSize: 24,
        },
    },
};

export const menuTheme = {
    components: {
        Menu: {
            itemBg: "transparent", // ana başlığın bg
            itemColor: "rgba(202, 202, 202, 1)", // bütün başlıkların color
            horizontalItemSelectedBg: "rgba(41, 41, 41, 1)", // seçilen ana başlığın bg
            horizontalItemHoverBg: 'transparent',
            hoverable: true,
            horizontalItemMargin: 16,
            horizontalItemPadding: '16px',
            horizontalItemSelectedColor: "#ffffff", // seçilen ana başlığın color
            horizontalItemBorderRadius: 8, // seçilen ana başlık radius
            subMenuItemBorderRadius: 8,
            popupBg: "rgba(41, 41, 41, 1)", // dropdown bg
            itemSelectedBg: "#000000", // dropdown içinde seçilen başlığın bg
            itemPaddingInline: '8px',
            itemSelectedColor: "#ffffff", // dropdown içinde seçilen başlığın color
            itemHoverBg: "rgba(0, 0, 0, 1)", // dropdown içindeki başlık hover olduğunda bg
            itemHoverColor: "#ffffff", // dropdown içindeki başlık hover olduğunda color
            itemActiveBg: "#000000", // dropdown içinde seçilen başlık bg
            paddingBlock: 18,
            itemMarginInline: 8, // dropdown içindeki başlıkların x ekseni margin
            itemMarginBlock: 8, // dropdown içindeki başlıkların y ekseni margin
            fontSize: 16,
            fontFamily: "Poppins, sans-serif",
        },
    },
};
