import React, {useEffect} from 'react';
import './MicrosoftPartnerPage.scss'
import {useAppContext} from "../../context/ContextProvider";
import MicrosoftPartner1 from "../../images/MicrosoftPartner1.png";
import MicrosoftPartner2 from "../../images/MicrosoftPartner2.png";
import classNames from "classnames";
import {Col, Row} from "antd";
import {useIsSmallScreen} from "../../hooks/useIsSmallScreen";
import PageHelmet from "../../components/page-helmet/PageHelmet";


interface MicrosoftPartnerType {
    id: number,
    title: string,
    description: string,
    image: string,
    imageOrder: number,
    textOrder: number,
}

const MicrosoftPartnerData: MicrosoftPartnerType[] = [
    {
        id: 1,
        title: "Microsoft İş Ortaklığı",
        description: "Microsoft iş ortaklığı sayesinde, yeni teknolojileri yakından izliyor ve bunları müşterilerimize en hızlı şekilde ulaştırabiliyoruz. Yazılım geliştirme ürünleri, deneme ortamları, demo kurulum gibi birçok olanağa sahip bulunmaktayız. Temel aldığımız Microsoft platformu bizim için ve müşterilerimiz için sürekli olarak gelişmekte, özellikle sunucu ve veritabanı teknolojilerinde ayrı bir araştırma-geliştirme kaynağımız gibi destek noktamız olmaktadır.",
        image: MicrosoftPartner1,
        imageOrder: 0,
        textOrder: 1,
    },
    {
        id: 2,
        title: "ISV Royalty Lisanslama",
        description: "Royalty anlaşması ile, başta Microsoft SQL Server olmak üzere birçok Microsoft ürününü müşterilerimize çok avantajlı fiyatlarla sunabiliyoruz. Yalnızca Derin Bilgi ürünleriyle kullanılabilen bu lisans tipiyle, yazılım sisteminizi yenilerken proje maliyetlerinizde etkili bir tasarruf elde ediyorsunuz. Özel anlaşmamız sonucu yaşanan kurulum ve lisanslama kolaylığı, apayrı bir avantaj sağlamaktadır.",
        image: MicrosoftPartner2,
        imageOrder: 1,
        textOrder: 0,
    },
]

const MicrosoftPartnerPage = () => {
    const classes = classNames('db-microsoft-partner-page')
    const isSmallScreen = useIsSmallScreen(991)
    const {setTempValue} = useAppContext();

    useEffect(() => {
        setTempValue("");
    }, []);

    return (
        <div className={classes}>
            <PageHelmet
                title={'Microsoft İş Ortaklığı'}
                description={'Microsoft İş Ortaklığı'}
                keywords={'microsoft iş ortaklığı, microsoft partner'}
                noIndex={false}
            />
            <div className="microsoft-partner">
                <div className="header-title">
                    Microsoft İş Ortaklıkları
                </div>
                {MicrosoftPartnerData.map((item) => (
                    <Row gutter={[64, 64]} className="microsoft-partner-item" key={item.id}>
                        <Col xs={24} lg={12} className="microsoft-partner-item-image"
                             order={!isSmallScreen ? item.imageOrder : 0}>
                            <img src={item.image} alt="microsoft-partner-img"/>
                        </Col>
                        <Col xs={24} lg={12}
                             order={!isSmallScreen ? item.textOrder : 1}>
                            <div className="header-subtitle">
                                {item.title}
                            </div>
                            <div className="microsoft-partner-item-description">
                                {item.description}
                            </div>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
};

export default MicrosoftPartnerPage;