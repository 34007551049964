import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import './HomeSliders.scss'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/bundle';
import HomeSlider from "./home-slider/HomeSlider";
import useApiHelper from "../../../hooks/useApiHelper";
import HomeSliderButtons from "./home-slider-buttons/HomeSliderButtons";
import {HomeSlidersType} from "../../../helpers/types";
import {Spin} from "antd";


const HomeSliders = () => {
    const classes = classNames('db-home-sliders');
    const {getHomeSliders} = useApiHelper();
    const [dataHomeSliders, setDataHomeSliders] = useState<HomeSlidersType[] | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);

    const getData = async () => {
        setLoad(true)
        try {
            const response = await getHomeSliders();
            if (response?.Slayts) {
                setDataHomeSliders(response.Slayts);
            }
        } catch (error) {
            console.error('getData error:', error);
        } finally {
            setLoad(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={classes}>
            <Spin spinning={load} fullscreen={true}/>
            <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                autoplay={{delay: 4000, disableOnInteraction: false}}
                speed={600}
                spaceBetween={32}
                grabCursor={true}
            >
                {dataHomeSliders && dataHomeSliders
                    .filter(homeCarousel => homeCarousel.aktif) // Sadece aktif olanları filtrele
                    .map((homeCarousel, index) => (
                        <SwiperSlide key={index}>
                            <HomeSlider homeSlider={homeCarousel}/>
                        </SwiperSlide>
                    ))
                }
                <HomeSliderButtons/>
            </Swiper>
        </div>
    );
};

export default HomeSliders;