import React, {useEffect, useState} from 'react';
import './HomeSliderButtons.scss';
import {useSwiper} from 'swiper/react';
import prevButton from '../../../../images/prevButton.svg';
import nextButton from '../../../../images/nextButton.svg';
import classNames from 'classnames';

export default function HomeSliderButtons() {
    const swiper = useSwiper();
    const [activeIndex, setActiveIndex] = useState(0);
    const [slidesCount, setSlidesCount] = useState(swiper.slides.length);

    useEffect(() => {
        const handleSlideChange = () => {
            setActiveIndex(swiper.activeIndex);
        };

        const handleInit = () => {
            setSlidesCount(swiper.slides.length);
        };

        swiper.on('slideChange', handleSlideChange);
        swiper.on('init', handleInit);
        swiper.on('update', handleInit);

        if (swiper.initialized) {
            handleInit();
        }

        return () => {
            swiper.off('slideChange', handleSlideChange);
            swiper.off('init', handleInit);
            swiper.off('update', handleInit);
        };
    }, [swiper]);

    const classes = classNames('db-home-slider-buttons');

    const goToNextSlide = () => {
        swiper.slideNext();
    };

    const goToPrevSlide = () => {
        swiper.slidePrev();
    };

    const isNextDisabled = activeIndex === slidesCount - 1;
    const isPrevDisabled = activeIndex === 0;

    const CustomPagination = () => {
        const slides: any[] = [];
        for (let i = 0; i < slidesCount; i++) {
            const pageNumber = i < 9 ? `0${i + 1}` : i + 1;
            const paginationItemClass = classNames('pagination-item', {
                'is-active': i === activeIndex,
            });
            slides.push(
                <div key={i} onClick={() => swiper.slideTo(i)} className={paginationItemClass}>
                    {pageNumber}
                </div>
            );
        }
        return <div className="pagination">{slides}</div>;
    };

    return (
        <div className={classes}>
            <div className="slide-buttons">
                <div onClick={goToPrevSlide} className={`prev-button ${isPrevDisabled ? 'disabled' : ''}`}>
                    <img src={prevButton} alt="Previous Slide"/>
                </div>
                <CustomPagination/>
                <div onClick={goToNextSlide} className={`next-button ${isNextDisabled ? 'disabled' : ''}`}>
                    <img src={nextButton} alt="Next Slide"/>
                </div>
            </div>
        </div>
    );
}
